<template>
  <div>
    <Nav :header="header"></Nav>
    <Leilao :configuracao="baseConfigLeilao"></Leilao>
    <Footer :texto="rodape" screen="eletronico" />
  </div>
</template>

<script>
import Nav from "@/components/shared/Nav.vue";

import Loading from "vue-loading-overlay";

import Footer from "@/components/shared/Footer.vue";

import "vue-loading-overlay/dist/vue-loading.css";

import Leilao from "@/components/Leilao.vue";

export default {
  name: "Eletronico",
  data: () => ({
    header: null,
    baseConfigLeilao: null,
    loading: false,
    leilao: "",
    hash: "",
    rodape: "",
  }),
  components: {
    Loading,
    Nav,
    Leilao,
    Footer,
  },
  mounted() {
    this.carregaHeader();
  },
  methods: {
    carregaHeader() {
      this.leilao = this.$route.params.name;
      this.hash = this.$route.params.hashValmiki;
      if (!this.leilao) {
        // alert("leilao não passado, caso o erro persista, entre contato com TI");
        this.$toastr.w(
          "LEILAO NÃO PASSADO, CASO O ERRO PERSISTA, ENTRE CONTATO COM TI"
        );

        console.error("o paramentro leilao não existe no header");
        this.loading = false;
        return;
      }
      let urlBaseConfig = `https://central.carbuy.com.br/frm3Realizacao/Json/Config.aspx?LeilaoCod=${this.leilao}&Hash=${this.hash}`;
      this.loading = true;
      this.$axios
        .get(`${urlBaseConfig}`)
        .then((response) => {
          const config = response.data;
          // let { Empresa, EmpresaLink, LeilaoData, ...restoConfig } =
          //   config.ConfigLeilao;
          this.rodape = config?.ConfigLeilao?.rodapeText;
          let logo;
          let corHeaderPorEmpresa = "primary";

          let lotesCombosList = config.LotesCombo;

          switch (config.ConfigLeilao.Empresa?.toLowerCase()) {
            case "vipleiloes":
              logo = config.ConfigLeilao.Empresa?.toLowerCase();
              break;

            case "leilaovip":
              logo = Empresa?.toLowerCase();
              break;
            case "hastavip":
              logo = config.ConfigLeilao.Empresa?.toLowerCase();
              this.corHeaderPorEmpresa = "warning";
              break;
            case "carbuy":
              logo = config.ConfigLeilao.Empresa?.toLowerCase();
              this.corHeaderPorEmpresa = "error";
              break;
            default:
              logo = "visitante";
              break;
          }
          let { ...restoConfig } = config.ConfigLeilao;
          this.header = {
            name: config.ConfigLeilao.Empresa,
            link: config.ConfigLeilao.EmpresaLink,
            logoEmpresa: logo,
            icon: require(`../assets/img/${logo}.png`),
            LeilaoData: config.ConfigLeilao.LeilaoData,
            restoConfig,
            corHeaderPorEmpresa,
          };

          this.baseConfigLeilao = { restoConfig, lotesCombosList };
        })
        .catch((e) => {
          console.error(
            "Excecao ao buscar dados da api para setar informações no header e configurações  ",
            e.message
          );

          this.$toastr.e("Erro no servidor");
        })
        .finally(() => (this.loading = false));
    },
  },
};
</script>

<style scoped>
</style>