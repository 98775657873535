<template>
  <div>
    <!-- <Nav /> -->
    <v-container>
      <loading
        :active.sync="loading"
        :can-cancel="false"
        loader="spinner"
        :width="130"
        :height="130"
        color="#0F5FFE"
      ></loading>
      <div v-if="showBackOnline">
        <v-tooltip right>
          <template v-slot:activator="{ on, attrs }">
            <b v-bind="attrs" v-on="on" style="float: right">
              <v-icon color="red">mdi-server-network-off</v-icon>
            </b>
          </template>
          <span>Sem conexão internet</span>
        </v-tooltip>
      </div>
      <!-- <h2
        style="float: left; font-weight: bold; color: #e3143b"
        v-if="listLote.length"
      >
        Aberto(s) ({{ listLote.length }})
      </h2> -->

      <!-- titulo total abertos -->
      <!-- <v-col cols="12"> -->
      <!-- <v-row style="display: flex; align-items: stretch"> -->
      <!-- <h2
            style="float: left; font-weight: bold; color: #e3143b"
            v-if="listLote.length"
          >
            {{ configLeilao.TermoLance }}(s) ({{ listLote.length }})
          </h2> -->

      <h2
        class="brade-session"
        style="color: #e5173f; font-size: 24px; font-weight: 900"
        v-if="listLote.length"
      >
        <span
          style="padding: 0 20px 0 0px !important"
          class="brade-titulo-oferta"
          >{{ listLote.length }} {{ configLeilao.TermoLance }}(s)
        </span>
      </h2>
      <!-- </v-row>
      </v-col> -->

      <!-- filtros mobile -->
      <v-col cols="12" class="brade-filters-mobile">
        <v-row style="display: flex; align-items: stretch">
          <v-col cols="12" class="brade-line">
            <!-- filtro de marca -->
            <v-combobox
              dense
              v-model="marcaSelecionada"
              :items="marcas"
              label="Marca"
              multiple
              chips
              color="red"
              style="margin: 10px; width: 100%"
            >
            </v-combobox>

            <!-- filtro de modelo -->
            <v-combobox
              dense
              v-model="modeloSelecionada"
              :items="modelos"
              multiple
              label="Modelo"
              chips
              color="red"
              style="margin: 10px; width: 100%"
            >
            </v-combobox>

            <!-- filtro de ano -->
            <v-combobox
              dense
              v-model="anoSelecionada"
              :items="anos"
              multiple
              label="Ano"
              chips
              color="red"
              style="margin: 10px; width: 100%"
            >
            </v-combobox>

            <!-- filtro de cores -->
            <v-combobox
              dense
              v-model="corSelecionada"
              :items="cores"
              label="Cor"
              multiple
              chips
              color="red"
              style="margin: 10px; width: 100%"
            >
            </v-combobox>

            <!-- filtro de localizacao -->
            <v-combobox
              dense
              v-model="localizacaoSelecionada"
              :items="localizacoes"
              label="Localização"
              multiple
              chips
              color="red"
              style="margin: 10px; width: 100%"
            >
            </v-combobox>

            <v-text-field-money
              ref="inputPrecoDe"
              v-on:change="
                carregaComoboxValmiki(
                  `&${paramentrosFiltroMarca}&${paramentrosFiltroModelo}&${paramentrosFiltroAno}&${paramentrosFiltroCor}&${paramentrosFiltroLocalizacao}&${paramentrosFiltroCombustivel}` +
                    `&KmDe=${kmSelecionadoDe}&KmAte=${kmSelecionadoAte}&PrecoDe=${precoSelecionadoDe}&PrecoAte=${precoSelecionadoAte}&Busca=${buscaLivre}`
                )
              "
              style="margin: 10px; width: 100%"
              class="white--text"
              v-model="precoSelecionadoDe"
              v-bind:label="labelPrecoSelecionadoDe"
              v-bind:properties="{
                prefix: 'R$',
                readonly: false,
                disabled: disabledPrecoSelecionado,
                outlined: false,
                clearable: true,
                placeholder: ' ',
              }"
              v-bind:options="{
                humanMask: '###.###.##0,00',
                machineMask: '########0.00',
                empty: null,
              }"
              v-bind:focus="focusPrecoSelecionado"
              v-on:focus="focusPrecoSelecionado = false"
            />

            <v-text-field-money
              ref="inputPrecoAte"
              v-on:change="
                carregaComoboxValmiki(
                  `&${paramentrosFiltroMarca}&${paramentrosFiltroModelo}&${paramentrosFiltroAno}&${paramentrosFiltroCor}&${paramentrosFiltroLocalizacao}&${paramentrosFiltroCombustivel}` +
                    `&KmDe=${kmSelecionadoDe}&KmAte=${kmSelecionadoAte}&PrecoDe=${precoSelecionadoDe}&PrecoAte=${precoSelecionadoAte}&Busca=${buscaLivre}`
                )
              "
              style="margin: 10px; width: 100%"
              class="white--text"
              v-model="precoSelecionadoAte"
              v-bind:label="labelPrecoSelecionadoAte"
              v-bind:properties="{
                prefix: 'R$',
                readonly: false,
                disabled: disabledPrecoSelecionado,
                outlined: false,
                clearable: true,
                placeholder: ' ',
              }"
              v-bind:options="{
                humanMask: '###.###.##0,00',
                machineMask: '########0.00',
                empty: null,
              }"
              v-bind:focus="focusPrecoSelecionado"
              v-on:focus="focusPrecoSelecionado = false"
            />

            <!-- filtro de km -->
            <v-text-field
              suffix="KM"
              type="number"
              v-model="kmSelecionadoDe"
              label="De"
              color="red"
              ref="inputKmDe"
              v-on:change="
                carregaComoboxValmiki(
                  `&${paramentrosFiltroMarca}&${paramentrosFiltroModelo}&${paramentrosFiltroAno}&${paramentrosFiltroCor}&${paramentrosFiltroLocalizacao}&${paramentrosFiltroCombustivel}` +
                    `&KmDe=${kmSelecionadoDe}&KmAte=${kmSelecionadoAte}&PrecoDe=${precoSelecionadoDe}&PrecoAte=${precoSelecionadoAte}&Busca=${buscaLivre}`
                )
              "
              style="margin: 10px; width: 100%"
            ></v-text-field>

            <v-text-field
              suffix="KM"
              type="number"
              v-model="kmSelecionadoAte"
              label="Até"
              color="red"
              ref="inputKmAte"
              style="margin: 10px; width: 100%"
              v-on:change="
                carregaComoboxValmiki(
                  `&${paramentrosFiltroMarca}&${paramentrosFiltroModelo}&${paramentrosFiltroAno}&${paramentrosFiltroCor}&${paramentrosFiltroLocalizacao}&${paramentrosFiltroCombustivel}` +
                    `&KmDe=${kmSelecionadoDe}&KmAte=${kmSelecionadoAte}&PrecoDe=${precoSelecionadoDe}&PrecoAte=${precoSelecionadoAte}&Busca=${buscaLivre}`
                )
              "
            ></v-text-field>

            <!-- filtro de busca livre -->
            <v-text-field
              v-model="buscaLivre"
              :loading="loadingBuscaLivre"
              ref="inputBusca"
              label="Ex: modelo ano cor"
              color="red"
              style="margin: 10px; width: 100%"
              prepend-inner-icon="mdi-search-web"
              v-on:change="
                carregaComoboxValmiki(
                  `&${paramentrosFiltroMarca}&${paramentrosFiltroModelo}&${paramentrosFiltroAno}&${paramentrosFiltroCor}&${paramentrosFiltroLocalizacao}&${paramentrosFiltroCombustivel}` +
                    `&KmDe=${kmSelecionadoDe}&KmAte=${kmSelecionadoAte}&PrecoDe=${precoSelecionadoDe}&PrecoAte=${precoSelecionadoAte}&Busca=${buscaLivre}`
                )
              "
            ></v-text-field>

            <v-btn
              @click="
                processoFiltrar(
                  'usuario',
                  precoSelecionadoDe,
                  precoSelecionadoAte,
                  kmSelecionadoDe,
                  kmSelecionadoAte,
                  buscaLivre
                )
              "
              color="#E02448"
              style="
                margin: 0;
                text-transform: capitalize;
                font-weight: regular;
                font-size: 14px;
              "
              dark
              elevation="0"
              title="Aplicar filtros"
              block
            >
              Filtrar
            </v-btn>
            <v-btn
              v-if="
                anoSelecionada.length !== 0 ||
                marcaSelecionada.length !== 0 ||
                modeloSelecionada.length !== 0 ||
                corSelecionada.length !== 0 ||
                localizacaoSelecionada.length !== 0 ||
                ![0.0, '', null, 'R$ 0,00', 'R$0,00', 0].includes(
                  precoSelecionadoDe
                ) ||
                ![0.0, '', null, 'R$ 0,00', 'R$0,00', 0].includes(
                  precoSelecionadoAte
                ) ||
                kmSelecionadoDe !== 1 ||
                kmSelecionadoAte !== 1 ||
                buscaLivre !== ''
              "
              @click="limparFiltros()"
              style="
                margin: 0;
                text-transform: capitalize;
                font-weight: regular;
                font-size: 14px;
                margin-top: 10px;
              "
              la
              color="#565656"
              dark
              elevation="0"
              title="Limpar filtros"
              block
            >
              Limpar filtros
            </v-btn>

            <v-alert
              v-if="estoquesCodSelecionado === 0"
              style="
                margin: 0;
                text-transform: capitalize;
                font-weight: regular;
                font-size: 14px;
                margin-top: 10px;
              "
              color="orange"
              dense
              type="info"
            >
              Nenhum item encontrado
            </v-alert>

            <!-- <v-btn
              @click="
                carregaCards(
                  anoSelecionada,
                  marcaSelecionada,
                  modeloSelecionada,
                  corSelecionada,
                  localizacaoSelecionada,
                  precoSelecionado,
                  kmSelecionadoDe,
                  kmSelecionadoAte,
                  buscaLivre
                )
              "
              class="brade-card brade-color-primary"
              style="margin: 10px"
              dark
              title="Aplicar filtros"
            >
              <v-icon left dark> mdi-clipboard-search-outline </v-icon>

              Filtrar
            </v-btn>

            <v-btn
              v-if="
                anoSelecionada !== '' ||
                marcaSelecionada !== '' ||
                modeloSelecionada !== '' ||
                corSelecionada !== '' ||
                localizacaoSelecionada !== '' ||
                ![0.0, '', null, 'R$ 0,00', 'R$0,00', 0].includes(
                  precoSelecionado
                ) ||
                kmSelecionadoDe !== 1 ||
                kmSelecionadoAte !== 1 ||
                buscaLivre !== ''
              "
              @click="limparFiltros()"
              class="brade-card brade-color-primary"
              style="margin: 10px"
              fab
              dark
              title="Limpar filtros"
              small
            >
              <v-icon dark> mdi-close-box </v-icon>
            </v-btn> -->
          </v-col>
        </v-row>
      </v-col>

      <!-- lista de lotes em abertos -->
      <v-col cols="12">
        <v-row justify="center" style="display: flex">
          <!-- filtros desktop -->
          <v-col
            class="brade-filters-desktop"
            style="padding: 0; max-width: 306px"
          >
            <div class="itf-sidecr">
              <!-- filtro de marca -->
              <v-combobox
                dense
                v-model="marcaSelecionada"
                :items="marcas"
                multiple
                label="Marca"
                class="brade-comobox"
                chips
                color="red"
                style="margin: 0"
              >
              </v-combobox>

              <!-- filtro de modelo -->
              <v-combobox
                dense
                v-model="modeloSelecionada"
                :items="modelos"
                multiple
                label="Modelo"
                chips
                color="red"
                class="brade-comobox"
                style="margin: 0"
              >
              </v-combobox>

              <!-- filtros de ano e cor -->
              <v-row class="ma-0">
                <v-col cols="12" class="d-flex justify-space-between pa-0">
                  <!-- filtro de ano -->
                  <v-combobox
                    dense
                    multiple
                    v-model="anoSelecionada"
                    :items="anos"
                    label="Ano"
                    chips
                    class="brade-comobox"
                    color="red"
                    style="margin: 0px; width: 126px; max-width: 126px"
                  >
                  </v-combobox>

                  <!-- filtro de cores -->
                  <v-combobox
                    dense
                    v-model="corSelecionada"
                    :items="cores"
                    label="Cor"
                    chips
                    multiple
                    class="brade-comobox"
                    color="red"
                    style="margin: 0px; width: 126px; max-width: 126px"
                  >
                  </v-combobox>
                </v-col>
              </v-row>

              <!-- filtro de localizacao -->
              <v-combobox
                dense
                v-model="localizacaoSelecionada"
                :items="localizacoes"
                label="Localização"
                chips
                multiple
                class="brade-comobox"
                color="red"
                style="margin: 0"
              >
              </v-combobox>

              <!-- filtros de precos -->

              <v-row class="ma-0">
                <v-col cols="12" class="d-flex justify-space-between pa-0">
                  <!-- filtro de preco de -->

                  <v-text-field-money
                    class="brade-comobox"
                    style="margin: 0px; width: 126px; max-width: 126px"
                    ref="inputPrecoDe"
                    v-on:change="
                      carregaComoboxValmiki(
                        `&${paramentrosFiltroMarca}&${paramentrosFiltroModelo}&${paramentrosFiltroAno}&${paramentrosFiltroCor}&${paramentrosFiltroLocalizacao}&${paramentrosFiltroCombustivel}` +
                          `&KmDe=${kmSelecionadoDe}&KmAte=${kmSelecionadoAte}&PrecoDe=${precoSelecionadoDe}&PrecoAte=${precoSelecionadoAte}&Busca=${buscaLivre}`
                      )
                    "
                    color="red"
                    v-model="precoSelecionadoDe"
                    v-bind:label="labelPrecoSelecionadoDe"
                    v-bind:properties="{
                      prefix: 'R$',
                      readonly: false,
                      disabled: disabledPrecoSelecionado,
                      outlined: false,
                      clearable: false,
                      placeholder: ' ',
                    }"
                    v-bind:options="{
                      humanMask: '###.###.##0,00',
                      machineMask: '########0.00',
                      empty: null,
                    }"
                    v-bind:focus="focusPrecoSelecionado"
                    v-on:focus="focusPrecoSelecionado = false"
                  />

                  <!-- filtro de preco ate -->

                  <v-text-field-money
                    class="brade-comobox"
                    style="margin: 0px; width: 126px; max-width: 126px"
                    ref="inputPrecoAte"
                    v-on:change="
                      carregaComoboxValmiki(
                        `&${paramentrosFiltroMarca}&${paramentrosFiltroModelo}&${paramentrosFiltroAno}&${paramentrosFiltroCor}&${paramentrosFiltroLocalizacao}&${paramentrosFiltroCombustivel}` +
                          `&KmDe=${kmSelecionadoDe}&KmAte=${kmSelecionadoAte}&PrecoDe=${precoSelecionadoDe}&PrecoAte=${precoSelecionadoAte}&Busca=${buscaLivre}`
                      )
                    "
                    color="red"
                    v-model="precoSelecionadoAte"
                    v-bind:label="labelPrecoSelecionadoAte"
                    v-bind:properties="{
                      prefix: 'R$',
                      readonly: false,
                      disabled: disabledPrecoSelecionado,
                      outlined: false,
                      clearable: false,
                      placeholder: ' ',
                    }"
                    v-bind:options="{
                      humanMask: '###.###.##0,00',
                      machineMask: '########0.00',
                      empty: null,
                    }"
                    v-bind:focus="focusPrecoSelecionado"
                    v-on:focus="focusPrecoSelecionado = false"
                  />
                </v-col>
              </v-row>

              <!-- filtros de km -->
              <v-row class="ma-0">
                <v-col cols="12" class="d-flex justify-space-between pa-0">
                  <!-- filtro de km de -->
                  <v-text-field
                    suffix="KM"
                    type="number"
                    v-model="kmSelecionadoDe"
                    label="De"
                    class="brade-comobox"
                    color="red"
                    ref="inputKmDe"
                    style="margin: 0px; width: 126px; max-width: 126px"
                    v-on:change="
                      carregaComoboxValmiki(
                        `&${paramentrosFiltroMarca}&${paramentrosFiltroModelo}&${paramentrosFiltroAno}&${paramentrosFiltroCor}&${paramentrosFiltroLocalizacao}&${paramentrosFiltroCombustivel}` +
                          `&KmDe=${kmSelecionadoDe}&KmAte=${kmSelecionadoAte}&PrecoDe=${precoSelecionadoDe}&PrecoAte=${precoSelecionadoAte}&Busca=${buscaLivre}`
                      )
                    "
                  ></v-text-field>

                  <!-- filtro de km ate -->

                  <v-text-field
                    suffix="KM"
                    type="number"
                    v-model="kmSelecionadoAte"
                    label="Até"
                    class="brade-comobox"
                    color="red"
                    ref="inputKmAte"
                    style="margin: 0px; width: 126px; max-width: 126px"
                    v-on:change="
                      carregaComoboxValmiki(
                        `&${paramentrosFiltroMarca}&${paramentrosFiltroModelo}&${paramentrosFiltroAno}&${paramentrosFiltroCor}&${paramentrosFiltroLocalizacao}&${paramentrosFiltroCombustivel}` +
                          `&KmDe=${kmSelecionadoDe}&KmAte=${kmSelecionadoAte}&PrecoDe=${precoSelecionadoDe}&PrecoAte=${precoSelecionadoAte}&Busca=${buscaLivre}`
                      )
                    "
                  ></v-text-field>
                </v-col>
              </v-row>

              <!-- filtro de busca -->
              <v-text-field
                v-model="buscaLivre"
                :loading="loadingBuscaLivre"
                ref="inputBusca"
                label="Ex: modelo ano cor"
                class="pa-0 brade-comobox"
                color="red"
                style="margin: 0"
                prepend-inner-icon="mdi-search-web"
                v-on:change="
                  carregaComoboxValmiki(
                    `&${paramentrosFiltroMarca}&${paramentrosFiltroModelo}&${paramentrosFiltroAno}&${paramentrosFiltroCor}&${paramentrosFiltroLocalizacao}&${paramentrosFiltroCombustivel}` +
                      `&KmDe=${kmSelecionadoDe}&KmAte=${kmSelecionadoAte}&PrecoDe=${precoSelecionadoDe}&PrecoAte=${precoSelecionadoAte}&Busca=${buscaLivre}`
                  )
                "
              ></v-text-field>
              <v-btn
                @click="
                  processoFiltrar(
                    'usuario',
                    precoSelecionadoDe,
                    precoSelecionadoAte,
                    kmSelecionadoDe,
                    kmSelecionadoAte,
                    buscaLivre
                  )
                "
                color="#E02448"
                style="
                  margin: 0;
                  text-transform: capitalize;
                  font-weight: regular;
                  font-size: 14px;
                "
                dark
                elevation="0"
                title="Aplicar filtros"
                width="100%"
              >
                <!-- <v-icon left dark> mdi-clipboard-search-outline </v-icon> -->
                Filtrar
              </v-btn>
              <v-btn
                v-if="
                  anoSelecionada.length !== 0 ||
                  marcaSelecionada.length !== 0 ||
                  modeloSelecionada.length !== 0 ||
                  corSelecionada.length !== 0 ||
                  localizacaoSelecionada.length !== 0 ||
                  ![0.0, '', null, 'R$ 0,00', 'R$0,00', 0].includes(
                    precoSelecionadoDe
                  ) ||
                  ![0.0, '', null, 'R$ 0,00', 'R$0,00', 0].includes(
                    precoSelecionadoAte
                  ) ||
                  kmSelecionadoDe !== 1 ||
                  kmSelecionadoAte !== 1 ||
                  buscaLivre !== ''
                "
                @click="limparFiltros()"
                style="
                  margin: 0;
                  text-transform: capitalize;
                  font-weight: regular;
                  font-size: 14px;
                  margin-top: 10px;
                "
                la
                color="#565656"
                dark
                elevation="0"
                title="Limpar filtros"
                width="100%"
              >
                <!-- <v-icon dark> mdi-close-box </v-icon> -->
                Limpar filtros
              </v-btn>

              <v-alert
                v-if="estoquesCodSelecionado === 0"
                style="
                  margin: 0;
                  text-transform: capitalize;
                  font-weight: regular;
                  font-size: 14px;
                  margin-top: 10px;
                "
                color="orange"
                dense
                type="info"
              >
                Nenhum item encontrado
              </v-alert>
            </div>
          </v-col>
          <!-- FIM filtros desktop -->

          <v-col
            class="listagem-card-brade"
            style="display: flex; flex-wrap: wrap; padding: 0"
            :style="isMobile() ? 'justify-content:center' : ''"
          >
            <v-card
              max-width="306"
              @click="carregaModal(lote)"
              max-height="620"
              class="brade-card"
              v-for="(lote, index) in listLote"
              :key="index"
              style="
                margin: 0px;
                box-shadow: 4px 4px 8px rgba(0, 0, 0, 0.15);
                border-radius: 10px;
              "
              min-width="306"
            >
              <div class="ribbon-wrapper" v-if="lote.Encerrado">
                <div class="glow">&nbsp;</div>
                <div class="ribbon-front">{{ lote.MsgLote }}</div>
              </div>
              <div
                class="ribbon-wrapper"
                v-if="lote.MsgLote === 'TEMPO ESGOTANDO !'"
              >
                <div class="glow">&nbsp;</div>
                <div class="ribbon-front">{{ lote.MsgLote }}</div>
              </div>
              <div
                class="ribbon-wrapper"
                v-if="lote.MsgLote === 'TEMPO EXTRA!'"
              >
                <div class="glow">&nbsp;</div>
                <div class="ribbon-front">{{ lote.MsgLote }}</div>
              </div>

              <v-img
                :eager="true"
                :src="
                  lote
                    ? lote.FotoCard
                    : 'http://cdn.vipleiloes.com/wac/vipleiloes/nao-disponivel.png'
                "
                height="228"
                @click="carregaModal(lote)"
                style="cursor: pointer"
              ></v-img>
              <v-list-item>
                <v-col cols="12">
                  <!-- <v-row justify="center"> -->
                  <h3 class="text-center brade-titulo-card">
                    {{ lote.Titulo }}
                  </h3>
                  <!-- </v-row> -->

                  <v-row justify="center"
                    ><p class="text-center">
                      {{ lote.Descricao1 }}
                    </p>
                  </v-row>
                  <v-row justify="center">
                    <!-- <v-divider class="brade-divider"></v-divider> -->
                    <v-progress-linear
                      :value="lote.Progresso"
                      height="10"
                      class="brade-divider"
                      color="red"
                      rounded
                      reverse
                    >
                      <!-- <template v-slot:default="{ value }">
                        <strong>{{ Math.ceil(value) }}%</strong>
                      </template> -->
                    </v-progress-linear>
                  </v-row>
                  <v-row justify="center">
                    <h3 class="subtitle-1 text-center">Valor atual</h3>
                  </v-row>
                  <v-row justify="center">
                    <h1
                      class="text-center"
                      style="color: #e5173f; padding-bottom: 20px"
                    >
                      {{
                        lote.VencendoValor === "R$ 0,00"
                          ? lote.ValorInicial
                          : lote.VencendoValor
                      }}
                    </h1>
                  </v-row>

                  <v-row justify="center" align-content="center">
                    <p class="text-center">
                      <v-icon size="26" color="red">mdi-timer-outline</v-icon
                      ><span
                        >Inicio da disputa em <b>{{ lote.Tempo }}</b>
                      </span>
                    </p>
                    <!-- <v-list-item-icon> -->

                    <!-- </v-list-item-icon> -->
                  </v-row>
                </v-col>
                <!-- <v-list-item-icon>
              <v-icon color="red">mdi-star</v-icon>
              {{ lote.LoteNumero }}
            </v-list-item-icon>
            <v-list-item-icon>
              <v-icon color="red">mdi-timer</v-icon>
              {{ lote.Tempo }}

            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title class="headline">{{
                lote.VencendoValor
              }}</v-list-item-title>
              <v-list-item-subtitle
                >vencendo {{ lote.VencendoNome }}</v-list-item-subtitle
              >
            </v-list-item-content> -->
              </v-list-item>

              <!-- <v-card-text
            :class="[lote.Descricao.length > 200 ? 'box' : 'background:black']"
          >
            <v-btn icon>
              <v-icon>mdi-bookmark-plus</v-icon>
            </v-btn>
            {{ lote.Descricao }}
          </v-card-text> -->

              <v-col cols="12">
                <v-row class="ma-0">
                  <v-col
                    cols="12"
                    class="d-flex justify-center pa-0"
                    style="font-size: 14px; align-items: center"
                  >
                    <v-icon
                      :color="corPorEmpresaCardHeader(configLeilao.Empresa)"
                      right
                      size="26"
                      >mdi-map-marker</v-icon
                    >
                    <!-- {{ lote.ExpostoCidade ? lote.ExpostoCidade : "Sem infos" }}
                    - -->
                    {{ lote.ExpostoUF }}

                    <v-icon
                      style="margin-left: 20px"
                      :color="corPorEmpresaCardHeader(configLeilao.Empresa)"
                      size="26"
                      right
                      >mdi-bag-suitcase</v-icon
                    ><b style="padding-right: 2px"
                      >{{ lote.VencendoNumero }}
                    </b>
                    {{ configLeilao.TermoQtd }}(s)
                  </v-col>
                </v-row>
              </v-col>
              <v-card-actions>
                <!-- <div style="padding-right: 10px">
              {{ lote.Lance }} {{ configLeilao.TermoLance }}
            </div> -->

                <!-- <v-btn
              small
              color="error"
              @click="carregaModal(lote)"
              v-if="lote.Tempo !== '00:00:00'"
              >Dar {{ configLeilao.TermoLance }}</v-btn
            > -->

                <p class="caption text-left" v-if="lote.Tempo !== '00:00:00'">
                  <!-- {{ configLeilao.TermoLeilao }}: -->
                  <span>{{ configLeilao.LeilaoData }}</span>
                </p>
                <v-chip class="ma-2" color="error" text-color="white" v-else>
                  Encerrado
                  <v-icon right>mdi-close-circle</v-icon>
                </v-chip>
                <v-spacer></v-spacer>
                <!-- <v-btn text color="deep-dark accent-4">Mais</v-btn> -->
                <!-- <v-btn @click="carregaModal(lote)" icon>
              <v-icon>mdi-hammer</v-icon>
            </v-btn>
            <v-btn @click="carregaModal(lote)" icon>
              <v-icon>mdi-comment-plus-outline</v-icon>
            </v-btn> -->
                <p class="caption text-right" v-if="lote.Tempo !== '00:00:00'">
                  {{ configLeilao.TermoLance }}
                  <span>{{ lote.LoteNumero }}</span>
                </p>
              </v-card-actions>
            </v-card>
          </v-col>
        </v-row>
      </v-col>

      <!-- lista de lotes em repasse -->
      <hr class="hr-text" v-if="listLotesEmpRepasse.length" />
      <h2
        style="float: left; font-weight: bold; color: #e3143b"
        v-if="listLotesEmpRepasse.length"
      >
        <!-- Total de {{ listLotesEmpRepasse.length }} -->
        Próximos ({{ listLotesEmpRepasse.length }})
      </h2>
      <v-row class="text-center">
        <v-card
          max-width="306"
          class="brade-card mx-auto"
          v-for="(lote, index) in listLotesEmpRepasse"
          :key="index"
          style="margin-bottom: 10px"
          :disabled="true"
          elevation="10"
          shaped
        >
          <div class="ribbon-wrapper" v-if="lote.LoteSituacao === 'REPASSE'">
            <div class="glow">&nbsp;</div>
            <div class="ribbon-front">
              <!-- {{ lote.LoteSituacao }}  -->
              PRÓXIMO ANÚNCIO
            </div>
          </div>

          <v-img
            :eager="true"
            :src="
              lote
                ? lote.FotoCard
                : 'http://cdn.vipleiloes.com/wac/vipleiloes/nao-disponivel.png'
            "
            height="194"
          ></v-img>
          <v-list-item>
            <v-col cols="12">
              <v-row justify="center">
                <h3 class="text-center">{{ lote.Titulo }}</h3>
              </v-row>

              <v-row justify="center"
                ><p class="text-center">
                  {{ lote.Descricao1 }}
                </p>
              </v-row>
              <v-row justify="center">
                <v-divider
                  class="brade-divider"
                  style="border-width: 4px !important; max-width: 50%"
                ></v-divider>
              </v-row>
              <v-row justify="center">
                <h3 class="subtitle-1 text-center">Valor atual</h3>
              </v-row>
              <v-row justify="center">
                <h1 class="text-center" style="color: #e5173f">
                  {{ lote.VencendoValor }}
                </h1>
              </v-row>

              <v-row justify="center" align-content="center">
                <p class="text-center">
                  <v-icon large color="red">mdi-timer-outline</v-icon
                  ><span
                    >Esta {{ configLeilao.TermoLance }} se encerra em
                    <b>{{ lote.Tempo }}</b>
                  </span>
                </p>
                <!-- <v-list-item-icon> -->

                <!-- </v-list-item-icon> -->
              </v-row>
            </v-col>
            <!-- <v-list-item-icon>
              <v-icon color="red">mdi-star</v-icon>
              {{ lote.LoteNumero }}
            </v-list-item-icon>
            <v-list-item-icon>
              <v-icon color="red">mdi-timer</v-icon>
              {{ lote.Tempo }}

            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title class="headline">{{
                lote.VencendoValor
              }}</v-list-item-title>
              <v-list-item-subtitle
                >vencendo {{ lote.VencendoNome }}</v-list-item-subtitle
              >
            </v-list-item-content> -->
          </v-list-item>
          <v-col cols="12">
            <v-row>
              <v-col cols="6">
                <v-icon
                  :color="corPorEmpresaCardHeader(configLeilao.Empresa)"
                  right
                  >mdi-map-marker</v-icon
                >
                {{ lote.ExpostoCidade ? lote.ExpostoCidade : "Sem infos" }}
                -
                {{ lote.ExpostoUF }}
              </v-col>

              <v-col cols="6">
                <v-icon
                  :color="corPorEmpresaCardHeader(configLeilao.Empresa)"
                  right
                  >mdi-bag-suitcase</v-icon
                >{{ configLeilao.TermoLance }} ID: {{ lote.LoteCod }}
              </v-col>
            </v-row>
          </v-col>

          <!-- <v-list-item>
            <v-list-item-icon>
              <v-icon color="red">mdi-star</v-icon>
              {{ lote.LoteNumero }}
            </v-list-item-icon>
            <v-list-item-icon>
              <v-icon color="red">mdi-timer</v-icon>
              {{ lote.Tempo }}
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title class="headline">{{
                lote.VencendoValor
              }}</v-list-item-title>
              <v-list-item-subtitle
                >vencendo {{ lote.VencendoNome }}</v-list-item-subtitle
              >
            </v-list-item-content>
          </v-list-item> -->
          <!-- 
          <v-img
            :eager="true"
            :src="
              lote
                ? lote.FotoCard
                : 'http://cdn.vipleiloes.com/wac/vipleiloes/nao-disponivel.png'
            "
            height="194"
          ></v-img> -->
          <!-- 
          <v-card-text
            :class="[lote.Descricao.length > 200 ? 'box' : 'background:black']"
          >
            <v-btn icon>
              <v-icon>mdi-bookmark-plus</v-icon>
            </v-btn>
            {{ lote.Descricao }}
            <p>{{ lote.VencendoApelido }}</p>
            <p>{{ lote.VencendoEstado }}</p>
          </v-card-text> -->

          <v-card-actions>
            <!-- <div style="padding-right: 10px">{{ lote.Lance }} ofertas</div> -->

            <!-- <v-btn small color="error" v-if="lote.Tempo !== '00:00:00'"
              >Dar {{ configLeilao.TermoLance }}</v-btn>
               -->
            <p class="caption text-left" v-if="lote.Tempo !== '00:00:00'">
              <span>{{ configLeilao.LeilaoData }}</span>
            </p>
            <v-chip class="ma-2" color="error" text-color="white" v-else>
              Repasse
              <v-icon right>mdi-close-circle</v-icon>
            </v-chip>
            <v-spacer></v-spacer>
            <!-- <v-btn text color="deep-dark accent-4">Mais</v-btn> -->
            <!-- <v-btn icon>
              <v-icon>mdi-hammer</v-icon>
            </v-btn>
            <v-btn icon>
              <v-icon>mdi-comment-plus-outline</v-icon>
            </v-btn> -->
            <p class="caption text-left" v-if="lote.Tempo !== '00:00:00'">
              {{ configLeilao.TermoLance }} <span>{{ lote.LoteNumero }}</span>
            </p>
          </v-card-actions>
        </v-card>
      </v-row>

      <!-- lista de lotes encerrados -->
      <hr class="hr-text" v-if="listLotesEncerrados.length" />
      <h2
        style="float: left; font-weight: bold; color: #e3143b"
        v-if="listLotesEncerrados.length"
      >
        Encerrou ({{ listLotesEncerrados.length }})
      </h2>
      <!-- lista de lotes encerrados -->
      <v-row class="text-center">
        <!-- <h1>Encerrados</h1> -->

        <v-card
          max-width="306"
          class="brade-card mx-auto"
          v-for="(lote, index) in listLotesEncerrados"
          :key="index"
          style="margin-bottom: 10px"
          :disabled="true"
          elevation="10"
          shaped
        >
          <div class="ribbon-wrapper" v-if="lote.LoteSituacao === 'ENCERRADO'">
            <div class="glow">&nbsp;</div>
            <div class="ribbon-front">
              {{
                lote.VencendoApelido
                  ? "VENCEDOR " + lote.VencendoApelido
                  : "SEM OFERTAS"
              }}
            </div>
          </div>

          <v-img
            :eager="true"
            :src="
              lote
                ? lote.FotoCard
                : 'http://cdn.vipleiloes.com/wac/vipleiloes/nao-disponivel.png'
            "
            height="194"
          ></v-img>

          <v-list-item>
            <v-col cols="12">
              <v-row justify="center">
                <h3 class="text-center">{{ lote.Titulo }}</h3>
              </v-row>

              <v-row justify="center"
                ><p class="text-center">
                  {{ lote.Descricao1 }}
                </p>
              </v-row>
              <v-row justify="center">
                <v-divider
                  class="brade-divider"
                  style="border-width: 4px !important; max-width: 50%"
                ></v-divider>
              </v-row>
              <v-row justify="center">
                <h3 class="subtitle-1 text-center">Valor atual</h3>
              </v-row>
              <v-row justify="center">
                <h1 class="text-center" style="color: #e5173f">
                  <!-- {{ lote.VencendoValor }} -->

                  {{
                    lote.VencendoValor === "R$ 0,00"
                      ? lote.ValorInicial
                      : lote.VencendoValor
                  }}
                </h1>
              </v-row>

              <v-row justify="center" align-content="center">
                <p class="text-center">
                  <v-icon large color="red">mdi-timer-outline</v-icon
                  ><span
                    >Esta {{ configLeilao.TermoLance }} se encerra em
                    <b>{{ lote.Tempo }}</b>
                  </span>
                </p>
                <!-- <v-list-item-icon> -->

                <!-- </v-list-item-icon> -->
              </v-row>
            </v-col>
            <!-- <v-list-item-icon>
              <v-icon color="red">mdi-star</v-icon>
              {{ lote.LoteNumero }}
            </v-list-item-icon>
            <v-list-item-icon>
              <v-icon color="red">mdi-timer</v-icon>
              {{ lote.Tempo }}

            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title class="headline">{{
                lote.VencendoValor
              }}</v-list-item-title>
              <v-list-item-subtitle
                >vencendo {{ lote.VencendoNome }}</v-list-item-subtitle
              >
            </v-list-item-content> -->
          </v-list-item>

          <v-col cols="12">
            <v-row>
              <v-col cols="6">
                <v-icon
                  :color="corPorEmpresaCardHeader(configLeilao.Empresa)"
                  right
                  >mdi-map-marker</v-icon
                >
                {{ lote.ExpostoCidade ? lote.ExpostoCidade : "Sem infos" }}
                -
                {{ lote.ExpostoUF }}
              </v-col>

              <v-col cols="6">
                <v-icon
                  :color="corPorEmpresaCardHeader(configLeilao.Empresa)"
                  right
                  >mdi-bag-suitcase</v-icon
                >{{ configLeilao.TermoLance }} Id: {{ lote.LoteCod }}
              </v-col>
            </v-row>
          </v-col>

          <!-- <v-list-item>
            <v-list-item-icon>
              <v-icon color="red">mdi-star</v-icon>
              {{ lote.LoteNumero }}
            </v-list-item-icon>
            <v-list-item-icon>
              <v-icon color="red">mdi-timer</v-icon>
              {{ lote.Tempo }}
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title class="headline">{{
                lote.VencendoValor
              }}</v-list-item-title>
              <v-list-item-subtitle
                >vencendo {{ lote.VencendoNome }}</v-list-item-subtitle
              >
            </v-list-item-content>
          </v-list-item>

          <v-img
            :eager="true"
            :src="
              lote
                ? lote.FotoCard
                : 'http://cdn.vipleiloes.com/wac/vipleiloes/nao-disponivel.png'
            "
            height="194"
          ></v-img> -->
          <!-- 
          <v-card-text
            :class="[lote.Descricao.length > 200 ? 'box' : 'background:black']"
          >
            <v-btn icon>
              <v-icon>mdi-bookmark-plus</v-icon>
            </v-btn>
            {{ lote.Descricao }}
            <p>{{ lote.VencendoApelido }}</p>
            <p>{{ lote.VencendoEstado }}</p>
          </v-card-text> -->

          <v-card-actions>
            <!-- <div style="padding-right: 10px">
              {{ lote.Lance }} {{ configLeilao.TermoLance }}
            </div> -->
            <p class="caption text-left" v-if="lote.Tempo !== '00:00:00'">
              {{ configLeilao.TermoLeilao }}:
              <span>{{ configLeilao.LeilaoData }}</span>
            </p>
            <v-btn small color="error" v-if="lote.Tempo !== '00:00:00'"
              >Dar {{ configLeilao.TermoLance }}</v-btn
            >
            <v-chip class="ma-2" color="error" text-color="white" v-else>
              Encerrado
              <v-icon right>mdi-close-circle</v-icon>
            </v-chip>
            <v-spacer></v-spacer>
            <!-- <v-btn text color="deep-dark accent-4">Mais</v-btn> -->
            <!-- <v-btn icon>
              <v-icon>mdi-hammer</v-icon>
            </v-btn>
            <v-btn icon>
              <v-icon>mdi-comment-plus-outline</v-icon>
            </v-btn> -->
            <p class="caption text-left" v-if="lote.Tempo !== '00:00:00'">
              {{ configLeilao.TermoLance }} <span>{{ lote.LoteNumero }}</span>
            </p>
          </v-card-actions>
        </v-card>
      </v-row>

      <!-- modal do lote aberto -->
      <v-dialog
        v-model="dialog"
        persistent
        :max-width="isMobile() ? '600px' : '1360px'"
        v-if="loteModal"
      >
        <v-card>
          <!-- <v-card-title>Lote {{loteModal.LoteNumero}}</v-card-title> -->
          <v-toolbar
            flat
            :color="corPorEmpresaCardHeader(configLeilao.Empresa)"
            dark
          >
            <v-toolbar-title
              >{{ configLeilao.TermoLance }} {{ loteModal.LoteNumero }}
              <!-- <v-icon color="white">mdi-timer</v-icon> -->
            </v-toolbar-title>
            <v-spacer></v-spacer>
            <!-- <span class="d-flex flex-row mb-6"> -->
            <v-icon color="white">mdi-timer</v-icon>
            Termina em {{ loteModal.Tempo }}
            <!-- </span> -->
            <v-btn
              :color="corPorEmpresaCardHeader(configLeilao.Empresa)"
              @click="fechaModal()"
              ><v-icon large> mdi-close </v-icon></v-btn
            >
          </v-toolbar>
          <v-tabs
            v-model="active_tab"
            :color="corPorEmpresaCardHeader(configLeilao.Empresa)"
            :centered="true"
          >
            <v-tab>
              <v-icon key="Inicio" left>mdi-comment-text</v-icon>Inicio
            </v-tab>
            <v-tab
              v-if="
                configLeilao.Empresa &&
                configLeilao.Empresa.toLowerCase() === 'carbuy'
              "
              @click="carregaInfosLote(loteModal.LoteCod, loteModal.LoteHash)"
            >
              <v-icon key="informações" left>mdi-book-search</v-icon>Informações
            </v-tab>

            <v-tab>
              <v-icon key="Video" left>mdi-youtube-play</v-icon>Video
            </v-tab>
            <!-- <v-tab>
              <v-icon key="Visitação virtual" left>mdi-loupe</v-icon>Visitação
              virtual
            </v-tab> -->
            <v-tab>
              <v-icon key="Fotos" left>mdi-image-area</v-icon>Fotos
            </v-tab>
            <v-tab
              key="Ofertas"
              @click="getLances(loteModal.LoteCod, loteModal.LoteHash)"
            >
              <v-icon left>mdi-account-multiple</v-icon
              >{{ configLeilao.TermoQtd }}
            </v-tab>

            <v-tab-item>
              <v-card flat>
                <v-card-text :class="isMobile() ? '' : 'd-flex'">
                  <v-col :cols="isMobile() ? '12' : '6'">
                    <h3
                      class="text-left"
                      style="
                        display: flex;
                        align-items: center;
                        padding-bottom: 5px;
                        justify-content: space-between;
                      "
                    >
                      {{ loteModal.Titulo }}
                      <v-btn
                        small
                        icon
                        @click="dialogFotos = true"
                        color="pink"
                      >
                        <v-icon>mdi-image-search</v-icon>
                      </v-btn>
                    </h3>

                    <div class="d-flex flex-no-wrap justify-space-between">
                      <p
                        v-if="loteModal.CodigoVirtual"
                        v-html="loteModal.CodigoVirtual"
                        class="text-center"
                        style="margin: auto"
                      ></p>
                    </div>
                  </v-col>

                  <v-col :cols="isMobile() ? '12' : '6'">
                    <v-row>
                      <v-col class="brade-left py-0" cols="6">
                        <p class="subtitle-1">
                          {{ configLeilao.TermoQtd }} atual de
                          {{ loteModal.LanceTotal }}
                        </p>
                        <v-icon>mdi-alert-circle-outline</v-icon
                        ><i style="font-size: 11px"
                          >Este é o valor à pagar, não existem taxas adicionais,
                          exceto custo de frete, caso contratado.</i
                        >
                        <v-alert color="light-green" colored-border>
                          <h3>
                            {{
                              loteModal.VencendoValor === "R$ 0,00"
                                ? loteModal.ValorInicial
                                : loteModal.VencendoValor
                            }}

                            {{ configLeilao.Comissao }}
                          </h3>
                        </v-alert>
                        <p>
                          <span>{{ configLeilao.TermoQtd }} Inicial:</span>
                          {{ loteModal.ValorInicial }}
                        </p>
                        <p v-if="loteModal.ValorMercado">
                          <span>Valor de Mercado: </span>
                          <!-- {{ loteModal.ValorMercado }} -->
                          <money
                            value
                            v-model="loteModal.ValorMercado"
                            v-bind="money"
                          ></money>
                        </p>
                        <hr />
                        <p v-html="loteModal.Descricao2"></p>
                        <b v-if="loteModal.ObservacaoVendedor"
                          >Observação do veículo:</b
                        >
                        {{ loteModal.ObservacaoVendedor }} <br />
                      </v-col>
                      <v-col class="py-0" :cols="isMobile() ? '12' : '6'">
                        <div class="brade-right" v-if="isHabilitado">
                          <div
                            class="subheading mx-3"
                            style="width: 90% !important"
                          >
                            <!-- <div v-if="isHabilitado"> -->
                            <v-row
                              justify="center"
                              v-if="loteModal.Tempo !== '00:00:00'"
                            >
                              <div v-if="loteModal.VencendoApelido">
                                <div class="subheading mx-3 text-center">
                                  <p color="#D32F2F">
                                    <span style="color: white">{{
                                      "Vencendo"
                                    }}</span>
                                  </p>
                                </div>
                                <div class="text-center">
                                  <p color="#D32F2F">
                                    <span style="color: white">{{
                                      loteModal.VencendoApelido
                                    }}</span>
                                  </p>
                                </div>
                              </div>
                            </v-row>
                            <!-- </div> -->
                            <!-- <div v-else>
                              <v-row justify="center">
                                <div v-if="loteModal.VencendoApelido">
                                  <div class="subheading mx-3 text-center">
                                    <p color="#D32F2F">
                                      <span style="color: white">{{
                                        "Vencendo"
                                      }}</span>
                                    </p>
                                  </div>
                                  <div class="text-center">
                                    <p color="#D32F2F">
                                      <span style="color: white">{{
                                        loteModal.VencendoApelido
                                      }}</span>
                                    </p>
                                  </div>
                                </div>
                              </v-row>
                            </div> -->
                          </div>
                        </div>
                        <div class="brade-right" style="margin-top: 20px">
                          <p
                            v-if="isHabilitado"
                            class="text-center"
                            style="font-weight: bold"
                          >
                            {{
                              loteModal.Tempo !== "00:00:00"
                                ? `Enviar ${configLeilao.TermoQtd}`
                                : `${loteModal.MsgLote}`
                            }}
                          </p>
                          <div v-if="isHabilitado">
                            <v-row
                              justify="center"
                              v-if="loteModal.Tempo !== '00:00:00'"
                            >
                              <div class="subheading mx-3">
                                <v-btn
                                  class="text-center"
                                  @click="DarLance(loteModal, loteModal.Botao1)"
                                  color="#EF5350"
                                  :loading="loadingBtn"
                                  :disabled="loadingBtn"
                                  ><span style="color: white">{{
                                    loteModal.Botao1
                                  }}</span></v-btn
                                >
                              </div>
                              <div class="subheading mx-3">
                                <v-btn
                                  @click="DarLance(loteModal, loteModal.Botao2)"
                                  :loading="loadingBtn"
                                  :disabled="loadingBtn"
                                  color="#D32F2F"
                                  ><span style="color: white">{{
                                    loteModal.Botao2
                                  }}</span>
                                </v-btn>
                              </div>
                              <div class="subheading mx-3">
                                <v-btn
                                  @click="DarLance(loteModal, loteModal.Botao3)"
                                  :loading="loadingBtn"
                                  :disabled="loadingBtn"
                                  color="#D32F2F"
                                  ><span style="color: white">{{
                                    loteModal.Botao3
                                  }}</span>
                                </v-btn>
                              </div>
                              <!-- <div v-if="loteModal.VencendoApelido">
                                <div class="subheading mx-3 text-center">
                                  <p color="#D32F2F">
                                    <span style="color: white">{{
                                      "Vencendo"
                                    }}</span>
                                  </p>
                                </div>
                                <div class="text-center">
                                  <p color="#D32F2F">
                                    <span style="color: white">{{
                                      loteModal.VencendoApelido
                                    }}</span>
                                  </p>
                                </div>
                              </div> -->
                            </v-row>
                          </div>
                          <div v-else>
                            <v-row justify="center">
                              <div class="subheading mx-3 text-center">
                                <span style="color: white"
                                  ><h2>
                                    <v-icon color="white"
                                      >mdi-eye-settings</v-icon
                                    >&nbsp; Espectador
                                  </h2></span
                                >
                                <br />
                              </div>
                              <div v-if="loteModal.VencendoApelido">
                                <div class="subheading mx-3 text-center">
                                  <p color="#D32F2F">
                                    <span style="color: white">{{
                                      "Vencendo"
                                    }}</span>
                                  </p>
                                </div>
                                <div class="text-center">
                                  <p color="#D32F2F">
                                    <span style="color: white">{{
                                      loteModal.VencendoApelido
                                    }}</span>
                                  </p>
                                </div>
                              </div>
                            </v-row>
                          </div>
                        </div>
                        <div
                          v-if="isHabilitado"
                          class="brade-right"
                          style="margin-top: 20px"
                        >
                          <v-text-field-money
                            class="white--text"
                            v-model="valorLanceAutomatico"
                            v-bind:label="labelLanceAutomatico"
                            v-bind:properties="{
                              dark: 'dark',
                              hint: 'Ofertas realizadas automaticas',
                              color: 'white',
                              prefix: 'R$ ',
                              readonly: false,
                              disabled: loteModal.LanceAutomatico !== '',
                              outlined: false,
                              clearable: false,
                              placeholder: ' ',
                            }"
                            v-bind:options="{
                              color: 'white',
                              humanMask: '###.###.##0,00',
                              machineMask: '########0.00',
                              empty: null,
                            }"
                            v-bind:focus="focusLanceAutomatico"
                            v-on:focus="focusLanceAutomatico = false"
                          />

                          <v-btn
                            v-if="
                              ['0,00', null, '', ' '].includes(
                                loteModal.LanceAutomatico
                              )
                            "
                            class="text-center"
                            @click="
                              processarLanceAutomatico(
                                loteModal,
                                valorLanceAutomatico
                              )
                            "
                            color="#EF5350"
                            ><span style="color: #e5173f"
                              >Cadastrar</span
                            ></v-btn
                          >
                          <v-btn
                            v-if="loteModal.LanceAutomatico"
                            class="text-center"
                            @click="processarLanceAutomatico(loteModal, 0)"
                            color="#EF5350"
                            ><span style="color: #e5173f">Cancelar</span></v-btn
                          >
                        </div>
                        <!-- laudo cautelar -->
                        <v-btn
                          v-if="loteModal.DocumentoLaudoCautelarArquivo"
                          style="margin-top: 20px"
                          :href="loteModal.DocumentoLaudoCautelarArquivo"
                          target="_blank"
                          color="#0D47A1"
                          depressed
                          class="white--text"
                          block
                        >
                          Download do laudo cautelar
                        </v-btn>
                      </v-col>
                    </v-row>
                  </v-col>
                </v-card-text>
              </v-card>
            </v-tab-item>

            <!-- tab de informacoes -->
            <v-tab-item>
              <v-card
                class="mx-auto"
                flat
                :max-width="isMobile() ? '550' : 'none'"
              >
                <div v-if="dadosInfoLote">
                  <div v-if="isMobile() === true">
                    <v-card-title>
                      <h3>
                        {{ dadosInfoLote.EstoqueVeiculoMarca }}
                        {{ dadosInfoLote.EstoqueVeiculoModelo }}
                        {{ dadosInfoLote.EstoqueVeiculoAno }}/{{
                          dadosInfoLote.EstoqueVeiculoAnoMod
                        }}
                      </h3>
                    </v-card-title>
                  </div>

                  <div v-else>
                    <v-container>
                      <v-card-title class="pa-0">
                        <h3>
                          {{ dadosInfoLote.EstoqueVeiculoMarca }}
                          {{ dadosInfoLote.EstoqueVeiculoModelo }}
                          {{ dadosInfoLote.EstoqueVeiculoAno }}/{{
                            dadosInfoLote.EstoqueVeiculoAnoMod
                          }}
                        </h3>
                      </v-card-title>
                    </v-container>
                  </div>

                  <!-- <v-card-text> -->
                  <v-container fluid>
                    <!-- <div
                      class="text-h2"
                      v-if="dadosInfoLote.DocumentoLaudoCautelarArquivo"
                    >
                      <p class="brade-title">Laudo cautelar</p>
    
                      <v-btn
                        :href="dadosInfoLote.DocumentoLaudoCautelarArquivo"
                        target="_blank"
                        color="#0D47A1"
                        depressed
                        class="white--text"
                      >
                        Download do laudo cautelar
                      </v-btn>
                    </div>
                    <br /> -->

                    <!-- <v-row>
                      <v-col :cols="isMobile() ? '12' : '6'">
                        <p class="brade-title py-0">Informações do item</p>
                        <v-row dense>
                          <v-col style="align-items: flex-start">
                            <v-card>
                              <div class="my-4 text-subtitle-1 brade-descricao">
                                <b>Categoria: </b
                                >{{ dadosInfoLote.SubClasseNome }}
                              </div>
                              <div class="my-4 text-subtitle-1 brade-descricao">
                                <b>Marca: </b
                                >{{ dadosInfoLote.EstoqueVeiculoMarca }}
                              </div>

                              <div class="my-4 text-subtitle-1 brade-descricao">
                                <b>Modelo: </b
                                >{{ dadosInfoLote.EstoqueVeiculoModelo }}
                              </div>
                              <div class="my-4 text-subtitle-1 brade-descricao">
                                <b>Ano/Ano modelo: </b
                                >{{ dadosInfoLote.EstoqueVeiculoAno }}/{{
                                  dadosInfoLote.EstoqueVeiculoAnoMod
                                }}
                              </div>

                              <v-card-actions>
                                <v-spacer></v-spacer>
                              </v-card-actions>
                            </v-card>
                          </v-col>
                          <v-col
                            class="brade-col-right"
                            style="align-items: flex-start"
                          >
                            <v-card>
                              <div class="my-4 text-subtitle-1 brade-descricao">
                                <b>Final da placa: </b
                                >{{ dadosInfoLote.EstoqueVeiculoPlacaFinal }}
                              </div>

                              <div class="my-4 text-subtitle-1 brade-descricao">
                                <b>Localização: </b
                                >{{ dadosInfoLote.EstoqueExpostoemCidade }} -
                                {{ dadosInfoLote.EstoqueExpostoemUF }}
                                {{ dadosInfoLote.EstoqueExpostoemEndereco }}
                                {{
                                  dadosInfoLote.EstoqueExpostoemEnderecoBairro
                                }}
                                {{ dadosInfoLote.EstoqueExpostoemEnderecoCEP }}
                              </div>
                              <v-card-actions>
                                <v-spacer></v-spacer>
                              </v-card-actions>
                            </v-card>
                          </v-col>
                        </v-row>
                      </v-col>
                      <v-col :cols="isMobile() ? '12' : '6'">
                        <p class="brade-title py-0">Detalhes do item</p>
                        <v-row dense>
                          <v-col style="align-items: flex-start">
                            <v-card>
 
                              <div class="my-4 text-subtitle-1 brade-descricao">
                                <b>Câmbio: </b
                                >{{ dadosInfoLote.EstoqueVeiculoCambioNome }}
                              </div>
                              <div class="my-4 text-subtitle-1 brade-descricao">
                                <b>Cor: </b
                                >{{ dadosInfoLote.EstoqueVeiculoCor }}
                              </div>
                              <div class="my-4 text-subtitle-1 brade-descricao">
                                <b>Km: </b>{{ dadosInfoLote.EstoqueVeiculoKm }}
                              </div>
                              <div class="my-4 text-subtitle-1 brade-descricao">
                                <b>Vidro elétrico: </b
                                >{{
                                  dadosInfoLote.EstoqueVeiculoVidroEletricoNome
                                }}
                              </div>
                              <div class="my-4 text-subtitle-1 brade-descricao">
                                <b>Trava: </b
                                >{{
                                  dadosInfoLote.EstoqueVeiculoTravaEletricaNome
                                }}
                              </div>
                            </v-card>
                          </v-col>
                          <v-col
                            class="brade-col-right"
                            style="align-items: flex-start"
                          >
                            <v-card>
                              <div class="my-4 text-subtitle-1 brade-descricao">
                                <b>Direção: </b
                                >{{
                                  dadosInfoLote.EstoqueVeiculoDirecaoHidraulicaNome
                                }}
                              </div>
                              <div class="my-4 text-subtitle-1 brade-descricao">
                                <b>Espelho eletrico: </b
                                >{{
                                  dadosInfoLote.EstoqueVeiculoVidroEletricoNome
                                }}
                              </div>
                              <div class="my-4 text-subtitle-1 brade-descricao">
                                <b>Combustível: </b
                                >{{ dadosInfoLote.EstoqueVeiculoCombustivel }}
                              </div>
                              <div class="my-4 text-subtitle-1 brade-descricao">
                                <b>Ar condicionado: </b
                                >{{
                                  dadosInfoLote.EstoqueVeiculoArcondicionalNome
                                }}
                              </div>
                            </v-card>
                          </v-col>
                        </v-row>
                      </v-col>
                    </v-row> -->

                    <p class="brade-title py-0">Informações importantes</p>
                    <v-row dense>
                      <v-col style="align-items: flex-start">
                        <v-card>
                          <div class="my-4 text-subtitle-1 brade-descricao">
                            <p
                              style="
                                text-align: justify;
                                font-size: 16px;
                                color: #444444;
                              "
                            >
                              Eventual divergência no padrão de placas Mercosul
                              no físico ou no documento do veículo (ATPVe), a
                              responsabilidade pela regularização será do
                              comprador, bem como eventuais pagamentos de taxas
                              e encargos exigidos na troca de placas. Ocorrendo
                              multa de averbação na transferência de documentos,
                              esta será por conta do comprador.
                            </p>
                          </div>
                        </v-card>
                      </v-col>
                      <!-- <v-col
                        class="brade-col-right"
                        style="align-items: flex-start"
                      >
                        <v-card>
                          <div class="my-4 text-subtitle-1 brade-descricao">
                            <p style="text-align: justify">
                              Visitação: {{ configLeilao.Visitacao }}
                            </p>
                          </div>
                        </v-card>
                      </v-col> -->
                    </v-row>

                    <p class="brade-title">Visitação</p>
                    <v-row dense>
                      <v-col style="align-items: flex-start">
                        <v-card>
                          <div class="my-4 text-subtitle-1 brade-descricao">
                            <p
                              style="
                                text-align: justify;
                                font-size: 16px;
                                color: #444444;
                              "
                              v-html="configLeilao.Visitacao"
                            ></p>
                            <p
                              style="
                                text-align: justify;
                                font-size: 16px;
                                color: #444444;
                              "
                            >
                              Local -
                              {{ dadosInfoLote.EstoqueExpostoemEndereco }}
                              {{ dadosInfoLote.EstoqueExpostoemEnderecoBairro }}
                              {{ dadosInfoLote.EstoqueExpostoemCidade }} -
                              {{ dadosInfoLote.EstoqueExpostoemUF }}
                              {{ dadosInfoLote.EstoqueExpostoemEnderecoCEP }}
                            </p>
                          </div>
                        </v-card>
                      </v-col>
                    </v-row>

                    <p class="brade-title">Forma de Pagamento</p>
                    <v-row dense>
                      <v-col style="align-items: flex-start">
                        <v-card>
                          <div class="my-4 text-subtitle-1 brade-descricao">
                            <p
                              style="
                                text-align: justify;
                                font-size: 16px;
                                color: #444444;
                              "
                            >
                              Através de Transferência Eletrônica, devendo ser
                              obrigatoriamente transferido pela conta de
                              propriedade do CNPJ do lojista cadastrado. A conta
                              de crédito para pagamento será em nome da Carbuy
                              Automotive Business Ltda. Prazo para pagamento é
                              até o dia {{ configLeilao.PrazoPagamento }}.
                            </p>
                          </div>
                        </v-card>
                      </v-col>
                    </v-row>

                    <!-- <p
                      v-if="dadosInfoLote.EstoqueObservacaoVendedor"
                      class="brade-title"
                    >
                      Observações do veículo
                    </p>
                    <v-row v-if="dadosInfoLote.EstoqueObservacaoVendedor" dense>
                      <v-col style="align-items: flex-start">
                        <v-card>
                          <div class="my-4 text-subtitle-1 brade-descricao">
                            <p
                              style="
                                text-align: justify;
                                font-size: 14px;
                                color: #444444;
                              "
                            >
                              {{ dadosInfoLote.EstoqueObservacaoVendedor }}
                            </p>
                          </div>
                        </v-card>
                      </v-col>
                    </v-row> -->

                    <!-- <p class="brade-title">Endereço</p>
                    <v-row dense>
                      <v-col style="align-items: flex-start">
                        <v-card>
                          <div class="my-4 text-subtitle-1 brade-descricao">
                            {{ dadosInfoLote.EstoqueExpostoemEndereco }}
                            {{ dadosInfoLote.EstoqueExpostoemEnderecoBairro }}
                            {{ dadosInfoLote.EstoqueExpostoemEnderecoCEP }}
                            {{ dadosInfoLote.EstoqueExpostoemUF }}
                          </div>
                        </v-card>
                      </v-col>
                    </v-row> -->

                    <!-- <br /> -->
                  </v-container>

                  <!-- </v-card-text>                       -->
                </div>
                <div v-else>Não possui informações</div>
              </v-card>
            </v-tab-item>
            <!-- tab de video -->
            <v-tab-item>
              <v-card class="mx-auto" flat>
                <v-card-text>
                  <div
                    style="display: flex; justify-content: center"
                    v-if="loteModal.CodigoYoutube"
                    v-html="loteModal.CodigoYoutube"
                  ></div>
                  <!-- <p v-else>Não encontado fotos</p> -->
                  <div
                    v-else
                    style="text-align: center; margin: auto; margin-top: 50px"
                  >
                    <h3>Não possui Video</h3>
                  </div>
                </v-card-text>
              </v-card>
            </v-tab-item>

            <!-- tab de fotos -->
            <v-tab-item>
              <v-card class="mx-auto" max-width="700" max-height="550" flat>
                <v-card-text>
                  <div
                    v-if="loteModal.FotosModal"
                    class="d-flex flex-no-wrap justify-space-between"
                  >
                    <p class="text-center" style="margin: auto">
                      <v-carousel style="cursor: pointer" hide-delimiters>
                        <v-carousel-item
                          @click="dialogFotos = true"
                          v-for="(img, index) in loteModal.FotosModal"
                          :key="index"
                          :src="img.FotoURL"
                          delimiter-icon="mdi-minus"
                        ></v-carousel-item>
                      </v-carousel>
                    </p>
                    <!-- <p else class="text-center" style="margin: auto">
                        <v-carousel
                          style="cursor: pointer"
                          height="auto"
                          v-if="loteModal.FotosModal"
                          hide-delimiters
                        >
                          <v-carousel-item
                            @click="dialogFotos = true"
                            v-for="(img, index) in loteModal.FotosModal"
                            :key="index"
                            :src="img.FotoURL"
                            delimiter-icon="mdi-minus"
                          ></v-carousel-item>
                        </v-carousel>
                      </p> -->
                  </div>

                  <!-- <v-carousel v-if="loteModal.FotosModal" hide-delimiters>
                    <v-carousel-item
                      v-for="(img, index) in loteModal.FotosModal"
                      :key="index"
                      :src="img.FotoURL"
                      delimiter-icon="mdi-minus"
                    ></v-carousel-item>
                  </v-carousel>
-->
                  <div
                    v-else
                    style="text-align: center; margin: auto; margin-top: 50px"
                  >
                    <h3>Não possui fotos</h3>
                  </div>
                </v-card-text>
              </v-card>
            </v-tab-item>

            <!-- tab de visitacao -->
            <!-- <v-tab-item>
              <v-card class="mx-auto" flat>
                <v-card-text>
                  <div
                    v-if="loteModal.CodigoVirtual"
                    v-html="loteModal.CodigoVirtual"
                  ></div>

                  <div
                    v-else
                    style="text-align: center; margin: auto; margin-top: 50px"
                  >
                    <h3>Não possui visitação virtual</h3>
                  </div>
                </v-card-text>
              </v-card>
            </v-tab-item> -->

            <!-- tab de lances -->
            <v-tab-item>
              <v-card flat>
                <v-card-text>
                  <v-simple-table fixed-header height="300px">
                    <template v-slot:default>
                      <thead>
                        <tr>
                          <th class="text-left">#</th>
                          <th class="text-left">Nome</th>
                          <th class="text-left">UF</th>
                          <th class="text-left">Valor</th>
                          <th class="text-left">Data</th>
                        </tr>
                      </thead>
                      <tbody v-if="listLance.length">
                        <tr v-for="(lance, index) in listLance" :key="index">
                          <td>{{ lance.NumeroLance }}</td>
                          <td>{{ lance.Apelido }}</td>
                          <td>{{ lance.PessoaUF }}</td>
                          <td>{{ lance.ValorLance }}</td>
                          <td>{{ lance.DataLance }}</td>
                        </tr>
                      </tbody>
                      <tfoot v-else>
                        <tr>
                          <td colspan="5" style="text-align: center">
                            <h4>Nenhum item encontrado</h4>
                          </td>
                        </tr>
                      </tfoot>
                    </template>
                  </v-simple-table>
                </v-card-text>
              </v-card>
            </v-tab-item>
          </v-tabs>
          <v-card-actions v-if="isMobile()">
            <v-btn
              :color="corPorEmpresaCardHeader(configLeilao.Empresa)"
              @click="fechaModal()"
              >Fechar</v-btn
            >
          </v-card-actions>
        </v-card>
      </v-dialog>
      <!-- fim modal do lote aberto -->

      <v-snackbar
        v-model="msgSnabar.snackbar"
        :top="msgSnabar.top"
        :left="msgSnabar.left"
      >
        {{ msgSnabar.text }}
        <template>
          <v-btn color="red" text @click="msgSnabar.snackbar = false">OK</v-btn>
        </template>
      </v-snackbar>

      <!-- botao de scroll  -->
      <v-btn
        v-scroll="onScroll"
        v-show="fab"
        fab
        fixed
        bottom
        left
        style="background: linear-gradient(90deg,#120000 0%,#e90000 100%);!important"
        @click="toTop"
      >
        <v-icon class="white--text">mdi-arrow-up-drop-circle-outline</v-icon>
      </v-btn>

      <!-- dialog de fotos tela cheia -->
      <v-dialog
        v-if="dialogFotos && loteModal"
        v-model="dialogFotos"
        fullscreen
        hide-overlay
        content-class="brade-imgfull"
        transition="dialog-bottom-transition"
      >
        <template v-slot:activator="{ on, attrs }">
          <v-btn color="primary" dark v-bind="attrs" v-on="on">
            Open Dialog
          </v-btn>
        </template>
        <v-card>
          <v-toolbar dark color="primary">
            <v-btn icon dark @click="dialogFotos = false">
              <v-icon>mdi-close</v-icon>
            </v-btn>
            <v-toolbar-title
              >{{ configLeilao.TermoLance }} {{ loteModal.LoteNumero }}
              {{ loteModal.Tempo }}</v-toolbar-title
            >
            <v-spacer></v-spacer>
            <v-toolbar-items>
              <v-btn dark text @click="dialogFotos = false"> Fechar </v-btn>
            </v-toolbar-items>
          </v-toolbar>

          <v-card-text>
            <v-carousel
              height="100%"
              v-if="loteModal.FotosModal"
              reverse-transition="fade-transition"
              transition="fade-transition"
            >
              <v-carousel-item
                v-for="(img, index) in loteModal.FotosModal"
                :key="index"
                :src="img.FotoURL"
                delimiter-icon="mdi-minus"
              ></v-carousel-item>
            </v-carousel>
          </v-card-text>
        </v-card>
      </v-dialog>
    </v-container>
  </div>
</template>

<script>
//Lista de lotes ao acessar eletronico baseado no leilao passado
// import lotes from "../assets/json/lotes.json";
// import Nav from "@/components/shared/Nav.vue";
// import timeServidor from "../assets/json/timeServidor.json";

// Import component
import Loading from "vue-loading-overlay";
// Import stylesheet
import "vue-loading-overlay/dist/vue-loading.css";

import moment from "moment";

import _ldsh from "lodash";

import { Money } from "v-money";

import MoneyCustom from "@/components/shared/Money.vue";

import { leilaoMixin } from "@/mixins/leilao.js";
import { helperMixin } from "@/mixins/helper.js";

export default {
  name: "Leilao",
  mixins: [leilaoMixin, helperMixin],
  // directives: { money: Money },
  props: {
    configuracao: {
      type: Object,
    },
  },
  components: {
    // Nav,
    Loading,
    Money,
    "v-text-field-money": MoneyCustom,
  },
  watch: {
    onLine: function (val) {
      if (this.onLine === true) {
        this.showBackOnline = true;
        setTimeout(() => {
          this.showBackOnline = false;
        }, 3000);
      }
    },
    marcaSelecionada: function (new_value, old_value) {
      // console.log("VELHO =>", old_value, "NOVO =>", new_value);
      let itensSelecionado = [];
      itensSelecionado = new_value;

      let qy =
        `&${this.paramentrosFiltroModelo}&${this.paramentrosFiltroAno}&${this.paramentrosFiltroCor}&${this.paramentrosFiltroLocalizacao}&${this.paramentrosFiltroCombustivel}` +
        `&KmDe=${this.kmSelecionadoDe}&KmAte=${this.kmSelecionadoAte}&PrecoAte=${this.precoSelecionado}&Busca=${this.buscaLivre}`;

      if (itensSelecionado.length) {
        this.paramentrosFiltroMarca = this.gerarQueryString(
          "Marca",
          itensSelecionado
        );

        qy += `&${this.paramentrosFiltroMarca}`;
      } else {
        this.paramentrosFiltroMarca = "";
      }

      this.carregaComoboxValmiki(qy);
    },
    modeloSelecionada: function (new_value, old_value) {
      // console.log("VELHO =>", old_value, "NOVO =>", new_value);
      let itensSelecionado = [];
      itensSelecionado = new_value;

      let qy =
        `&${this.paramentrosFiltroMarca}&${this.paramentrosFiltroAno}&${this.paramentrosFiltroCor}&${this.paramentrosFiltroLocalizacao}&${this.paramentrosFiltroCombustivel}` +
        `&KmDe=${this.kmSelecionadoDe}&KmAte=${this.kmSelecionadoAte}&PrecoAte=${this.precoSelecionado}&Busca=${this.buscaLivre}`;
      if (itensSelecionado.length) {
        this.paramentrosFiltroModelo = this.gerarQueryString(
          "Modelo",
          itensSelecionado
        );

        qy += `&${this.paramentrosFiltroModelo}`;
      } else {
        this.paramentrosFiltroModelo = "";
      }
      this.carregaComoboxValmiki(qy);
    },
    anoSelecionada: function (new_value, old_value) {
      // console.log("VELHO =>", old_value, "NOVO =>", new_value);

      let itensSelecionado = [];
      itensSelecionado = new_value;
      let qy =
        `&${this.paramentrosFiltroMarca}&${this.paramentrosFiltroModelo}&${this.paramentrosFiltroCor}&${this.paramentrosFiltroLocalizacao}&${this.paramentrosFiltroCombustivel}` +
        `&KmDe=${this.kmSelecionadoDe}&KmAte=${this.kmSelecionadoAte}&PrecoAte=${this.precoSelecionado}&Busca=${this.buscaLivre}`;
      if (itensSelecionado.length) {
        this.paramentrosFiltroAno = this.gerarQueryString(
          "Ano",
          itensSelecionado
        );

        qy += `&${this.paramentrosFiltroAno}`;
      } else {
        this.paramentrosFiltroAno = "";
      }

      this.carregaComoboxValmiki(qy);
    },
    corSelecionada: function (new_value, old_value) {
      // console.log("VELHO =>", old_value, "NOVO =>", new_value);

      let itensSelecionado = [];
      itensSelecionado = new_value;
      let qy =
        `&${this.paramentrosFiltroMarca}&${this.paramentrosFiltroModelo}&${this.paramentrosFiltroAno}&${this.paramentrosFiltroLocalizacao}&${this.paramentrosFiltroCombustivel}` +
        `&KmDe=${this.kmSelecionadoDe}&KmAte=${this.kmSelecionadoAte}&PrecoAte=${this.precoSelecionado}&Busca=${this.buscaLivre}`;

      if (itensSelecionado.length) {
        this.paramentrosFiltroCor = this.gerarQueryString(
          "Cor",
          itensSelecionado
        );

        qy += `&${this.paramentrosFiltroCor}`;
      } else {
        this.paramentrosFiltroCor = "";
      }
      this.carregaComoboxValmiki(qy);
    },
    // combustivelSelecionada: function (new_value, old_value) {
    //   // console.log("VELHO =>", old_value, "NOVO =>", new_value);
    //   let itensSelecionado = [];
    //   itensSelecionado = new_value;

    //   if (itensSelecionado.length) {
    //     this.paramentrosFiltroCombustivel = this.gerarQueryString(
    //       "Combustivel",
    //       itensSelecionado
    //     );

    //     let qy =
    //       `&${this.paramentrosFiltroMarca}&${this.paramentrosFiltroModelo}&${this.paramentrosFiltroAno}&${this.paramentrosFiltroCor}&${this.paramentrosFiltroLocalizacao}&${this.paramentrosFiltroCombustivel}` +
    //       `&KmDe=${this.kmSelecionadoDe}&KmAte=${this.kmSelecionadoAte}&PrecoAte=${this.precoSelecionado}&Busca=${this.buscaLivre}`;

    //     this.carregaComoboxValmiki(qy);
    //   }
    // },
    localizacaoSelecionada: function (new_value, old_value) {
      let itensSelecionado = [];
      itensSelecionado = new_value;

      let qy =
        `&${this.paramentrosFiltroMarca}&${this.paramentrosFiltroModelo}&${this.paramentrosFiltroAno}&${this.paramentrosFiltroCor}&${this.paramentrosFiltroCombustivel}` +
        `&KmDe=${this.kmSelecionadoDe}&KmAte=${this.kmSelecionadoAte}&PrecoAte=${this.precoSelecionado}&Busca=${this.buscaLivre}`;
      if (itensSelecionado.length) {
        this.paramentrosFiltroLocalizacao = this.gerarQueryString(
          "Localizacao",
          itensSelecionado
        );

        qy += `&${this.paramentrosFiltroLocalizacao}`;
      } else {
        this.paramentrosFiltroLocalizacao = "";
      }
      this.carregaComoboxValmiki(qy);
    },
    // estoquesCodSelecionado: function (new_value, old_value) {
    //   //console.log("VELHO =>", old_value, "NOVO =>", new_value);
    //   this.estoquesCodSelecionado = new_value;
    // },
  },
  computed: {
    listagemCards() {
      var resultado = [];
      this.listLotesCombo.map((ele) => {
        let baseUrlCard = `https://central.carbuy.com.br/frm3Realizacao/Json/Lotes.aspx?LeilaoCod=${this.leilao}&Hash=${this.hash}&LoteCod=${ele.LoteCod}`;
        this.$axios
          .get(`${baseUrlCard}`)
          .then((response) => {
            if (!Array.isArray(response.data.ListLotes)) {
              this.$toastr.e(response.data.Retorno.Descricao);
              return;
            }
            if (!response.data.ListLotes.length) {
              this.$toastr.w("Vazio");
              return;
            }

            // this.$toastr.s("Listagem lotes");
            this.msgSnabar.snackbar = true;
            this.msgSnabar.text = "Carregado listagem";

            resultado.push(response.data.ListLotes[0]);
            this.nextLote();
          })
          .catch((e) => {
            this.errorProcesso("Erro no servidor", "e");
          })
          .finally(() => {
            // this.setCronomentro();
            // this.nextLote();
          });
      });
      return resultado.sort();
    },
  },
  data() {
    return {
      estoquesCodSelecionado: "",
      paramentrosFiltroMarca: "",
      paramentrosFiltroLocalizacao: "",
      paramentrosFiltroModelo: "",
      paramentrosFiltroAno: "",
      paramentrosFiltroCor: "",
      paramentrosFiltroCombustivel: "",
      precoSelecionado: 0.0,
      precoSelecionadoDe: 0.0,
      precoSelecionadoAte: 0.0,
      labelPrecoSelecionadoDe: "Preço de",
      labelPrecoSelecionadoAte: "Preço até",
      focusPrecoSelecionado: false,
      disabledPrecoSelecionado: false,
      kmSelecionadoDe: 1,
      kmSelecionadoAte: 1,
      dialogFotos: false,
      isHabilitado: true,
      loadingBuscaLivre: false,
      buscaLivre: "",
      localizacaoSelecionada: [],
      localizacoes: [],
      marcaSelecionada: [],
      marcas: [],
      modeloSelecionada: [],
      modelos: [],
      anoSelecionada: [],
      anos: [],
      corSelecionada: [],
      cores: [],
      combustivelSelecionada: [],
      combustiveis: [],
      showBackOnline: false,
      active_tab: "Inicio",
      conexaoRt: null,
      msgSnabar: {
        snackbar: false,
        text: "",
        top: false,
        left: false,
      },
      listLote: [],
      dialog: false,
      dialog2: false,
      loteModal: {},
      tempoServido: "",
      contatorCard: "",
      polling: null,
      statusLoteCard: "",
      initTime: 0,
      loading: false,
      leilao: "",
      cronometroCard: null,
      listLance: [],
      dadosInfoLote: {},
      configLeilao: {},
      msgIncrementandoTime: null,
      hash: "",
      fotosLotes: [],
      usuarioLogadoDados: {
        codPessoaLogado: "",
        hashPessoaLogado: "",
        apelidoPessoaLogado: "",
      },
      loadingBtn: false,
      listLotesCombo: [],
      listTempo: [],
      rAF_ID: null,
      onLine: navigator.onLine ? true : false,
      listLotesEncerrados: [],
      listLotesEmpRepasse: [],
      fab: false,
      infoServidor: false,
      listLotesState: [],
      tempoAtualDoServidor: null,
      lotePreSelecionado: "",
      money: {
        decimal: ",",
        thousands: ".",
        prefix: "R$ ",
        precision: 2,
        masked: false /* doesn't work with directive */,
      },
      valorLanceAutomatico: "",
      labelLanceAutomatico: "Oferta automática",
      focusLanceAutomatico: false,
      disabledLanceAutomatico: false,
    };
  },
  created() {
    this.carregaDadosUrl();
    this.carregaTempoServidor();
    this.carregaComoboxValmiki();

    this.carregaCards("init");

    //console.log(this.isMobile());

    // this.initWebSocket();

    this.initConfigUrl();
    // setTimeout(() => {
    //   this.carregaCards("init");
    // }, 1000);
    setTimeout(() => {
      this.exibeLotePreSelecionado(this.lotePreSelecionado);
    }, 2800);
    //this.pollData();
  },
  methods: {
    processoFiltrar(
      tipoCarregado = "usuario",
      precoDe = 0.0,
      precoAte = 0.0,
      kmDe = 1,
      kmAte = 1,
      buscaLivre = ""
    ) {
      // let paramentrosFiltroComobox = "";
      // let paramentrosCard = "";
      this.precoSelecionadoDe = precoDe;
      this.precoSelecionadoAte = precoAte;
      this.kmSelecionadoDe = kmDe;
      this.kmSelecionadoAte = kmAte;
      this.buscaLivre = buscaLivre;
      // paramentrosFiltroComobox =
      //   `&${this.paramentrosFiltroMarca}&${this.paramentrosFiltroModelo}&${this.paramentrosFiltroAno}&${this.paramentrosFiltroCor}&${this.paramentrosFiltroLocalizacao}&${this.paramentrosFiltroCombustivel}` +
      //   `&KmDe=${this.kmSelecionadoDe}&KmAte=${this.kmSelecionadoAte}&PrecoAte=${this.precoSelecionado}&Busca=${this.buscaLivre}`;
      // this.carregaComoboxValmiki(paramentrosFiltroComobox);

      let inputBusca = this.$refs.inputBusca;
      let inputKmDe = this.$refs.inputKmDe;
      let inputKmAte = this.$refs.inputKmAte;
      let inputPrecoDe = this.$refs.inputPrecoDe;
      let inputPrecoAte = this.$refs.inputPrecoAte;
      if (tipoCarregado == "usuario") {
        if (inputBusca) {
          inputBusca.blur();
        }
        if (inputKmDe) {
          inputKmDe.blur();
        }
        if (inputKmAte) {
          inputKmAte.blur();
        }
        if (inputPrecoDe) {
          inputPrecoDe.blur = true;
        }
        if (inputPrecoAte) {
          inputPrecoAte.blur = true;
        }
      }

      setTimeout(() => {
        this.carregaCards(
          tipoCarregado,
          this.precoSelecionadoDe,
          this.precoSelecionadoAte,
          this.kmSelecionadoDe,
          this.kmSelecionadoAte,
          this.buscaLivre
        );
      }, 400);
    },
    processarLanceAutomatico(dadosLote, valor) {
      if (!["R$ 0,00", null, ""].includes(valor)) {
        let msgUsuario =
          valor === 0
            ? "Cancelado lance automático"
            : "Iniciado lance automático";

        let url = `https://central.carbuy.com.br/frm3Realizacao/Json/Grava.aspx?Tipo=Automatico&LoteCod=${dadosLote.LoteCod}&LoteHash=${dadosLote.LoteHash}&Hash=${this.hash}&LeilaoCod=${this.leilao}&PessoaCod=${this.usuarioLogadoDados.codPessoaLogado}&Apelido=${this.usuarioLogadoDados.apelidoPessoaLogado}&PessoaHash=${this.usuarioLogadoDados.hashPessoaLogado}&ValorAutomatico=${valor}`;

        let formDataLance = new FormData();

        let numeroLanceVencendo =
          dadosLote.VencendoNumero !== "-"
            ? Number(dadosLote.VencendoNumero) + 1
            : 1;

        formDataLance.append(
          "json",
          JSON.stringify({
            PessoaCod: this.usuarioLogadoDados.codPessoaLogado,
            LanceNumero: numeroLanceVencendo,
            ValorLance: valor,
            URL: window.location.href,
            Apelido: this.usuarioLogadoDados.apelidoPessoaLogado,
          })
        );
        this.$axios
          .post(`${url}`, formDataLance, {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          })
          .then((response) => {
            const corpoRetorno = response.data.ListLotes[0];

            //
            corpoRetorno.Tempo = dadosLote.Tempo;

            this.loteModal = corpoRetorno;
            this.valorLanceAutomatico = this.loteModal?.LanceAutomatico;
            // this.loteModal = corpoRetorno
            // this.$toastr.s("Lance enviado");
            this.msgSnabar.top = true;
            this.playSound("audio4.mp3");
            this.$toastr.s(msgUsuario);
          })
          .catch((e) => {
            this.$toastr.e("Erro no servidor");
            this.loadingBtn = false;
            this.playSound("audio6.mp3");
          })
          .finally(() => (this.loadingBtn = false));
      } else {
        this.$toastr.w("Valor automatico vazio");
      }
    },
    limparFiltros() {
      this.anoSelecionada = [];
      // this.combustivelSelecionada = "";
      this.marcaSelecionada = [];
      this.modeloSelecionada = [];
      this.localizacaoSelecionada = [];
      this.corSelecionada = [];
      this.buscaLivre = "";
      this.precoSelecionado = 0.0;
      this.precoSelecionadoDe = 0.0;
      this.precoSelecionadoAte = 0.0;
      this.kmSelecionadoDe = 1;
      this.kmSelecionadoAte = 1;
      this.estoquesCodSelecionado = "";
      // this.estoquesCodSelecionado = "";
      this.paramentrosFiltroMarca = "";
      this.paramentrosFiltroModelo = "";
      this.paramentrosFiltroAno = "";
      this.paramentrosFiltroCor = "";
      this.paramentrosFiltroCombustivel = "";
      this.paramentrosFiltroLocalizacao = "";

      this.carregaComoboxValmiki();
      this.carregaCards("init");
    },
    carregaDadosUrl() {
      this.leilao = this.$route.params.name;
      this.hash = this.$route.params.hashValmiki;
      this.usuarioLogadoDados.codPessoaLogado = this.$route.params.codCliente;
      this.usuarioLogadoDados.hashPessoaLogado = this.$route.params.hashPessoa;
      this.usuarioLogadoDados.apelidoPessoaLogado = this.$route.params.apelido;
      this.lotePreSelecionado = this.$route.params.loteDetaque;
    },
    initConfigUrl() {
      if (this.configuracao) {
        this.configLeilao = this.configuracao.restoConfig;
        this.listLotesCombo = this.configuracao.lotesCombosList;
      } else {
        this.pegarConfiguracaoForcado(this.leilao, this.hash);
      }

      if (!this.leilao) {
        // P
        this.$toastr.w("o paramentro leilao não existe");

        // this.loading = false;
      }
    },
    errorProcesso(info, tipo) {
      clearInterval(this.cronometroCard);
      // clearTimeout(this.msgIncrementandoTime);

      // alert(info);
      this.$toastr[tipo](info);

      return;
    },
    initWebSocket: async function () {
      this.conexaoRt =
        ws.getSubscription("clienteEletronico") ||
        ws.subscribe("clienteEletronico");
      let conexao = this.conexaoRt;

      conexao.on("cliente", (evt) => {
        //
        this.ClienteOnlineEntrou(evt);
      });
    },
    carregaCards(
      tipoCarregado = "usuario",
      preco = 0.0,
      kmDe = 1,
      kmAte = 1,
      buscaLivre = ""
    ) {
      if (this.estoquesCodSelecionado === 0) {
        this.$toastr.w("Não localizado itens");
        return;
      }

      this.loadingBuscaLivre = true;

      //let baseUrlCard = `https://central.carbuy.com.br/frm3Realizacao/Json/Lotes.aspx?LeilaoCod=${this.leilao}&Hash=${this.hash}&PessoaCod=${this.usuarioLogadoDados.codPessoaLogado}&PessoaHash=${this.usuarioLogadoDados.hashPessoaLogado}&Ano=${this.anoSelecionada}&Marca=${this.marcaSelecionada}&Modelo=${this.modeloSelecionada}&Cor=${this.corSelecionada}&Localizacao=${this.localizacaoSelecionada}&KmDe=${this.kmSelecionadoDe}&KmAte=${this.kmSelecionadoAte}&PrecoAte=${this.precoSelecionado}&Busca=${this.buscaLivre}`;

      let baseUrlCard = `https://central.carbuy.com.br/frm3Realizacao/Json/Lotes.aspx?LeilaoCod=${this.leilao}&Hash=${this.hash}&PessoaCod=${this.usuarioLogadoDados.codPessoaLogado}&PessoaHash=${this.usuarioLogadoDados.hashPessoaLogado}${this.estoquesCodSelecionado}`;

      this.$axios
        .get(`${baseUrlCard}`)
        .then((response) => {
          const jsonLotes = response.data;
          if (!Array.isArray(jsonLotes.ListLotes)) {
            this.$toastr.e(jsonLotes.Retorno.Descricao);
            return;
          }
          if (!jsonLotes.ListLotes.length) {
            this.$toastr.w("Vazio");
            return;
          }

          let lotesListagemOrdenado = jsonLotes.ListLotes.sort(
            (a, b) => a.LoteNumero - b.LoteNumero
          );
          let situacoesLotes = _ldsh.groupBy(
            lotesListagemOrdenado,
            (lt) => lt.LoteSituacao
          );

          if (
            !situacoesLotes.ABERTO &&
            !situacoesLotes.ENCERRADO &&
            !situacoesLotes.REPASSE // repasse so quando for carbuy
          ) {
            this.$router.push("/");
            this.$toastr.w(
              "Não possui lotes em abertos e nem encerrados e nem em repasse"
            );
          }
          //

          // if (!situacoesLotes.ABERTO) {
          //this.listLote = [];
          if (situacoesLotes.ENCERRADO) {
            if (this.configLeilao.Empresa?.toLowerCase() === "carbuy") {
              this.$router
                .push({
                  name: "Martelinho",
                  params: {
                    name: this.$route.params.name,
                    hashValmiki: this.$route.params.hashValmiki,
                    codPessoaLogado: this.$route.params.codCliente,
                    hashPessoaLogado: this.$route.params.hashPessoa,
                    apelido: this.$route.params.apelido,
                    loteDetaque: this.lotePreSelecionado,
                  },
                })
                .catch((err) => {});
            } else {
              this.listLotesEncerrados = situacoesLotes.ENCERRADO.map((ltE) => {
                ltE.Tempo = "00:00:00";
                ltE.MsgLote = "";
                return ltE;
              });
            }
          } else {
            this.listLotesEncerrados = [];
          }

          if (situacoesLotes.REPASSE) {
            this.listLotesEmpRepasse = situacoesLotes.REPASSE.map((ltE) => {
              ltE.Tempo = "00:00:00";
              ltE.MsgLote = "";
              return ltE;
            });
          } else {
            // this.listLotesEncerrados = [];
            this.listLotesEmpRepasse = [];
          }
          //return;
          // } else {
          //   this.listLote = [];
          // }
          this.msgSnabar.snackbar = true;
          this.msgSnabar.text = "Carregado listagem";
          if (this.infoServidor) {
            this.listLotesState = situacoesLotes.ABERTO;
          }
          let antigaLista = this.listLote;

          if (situacoesLotes.ABERTO) {
            this.listLote = situacoesLotes.ABERTO;
          } else {
            this.listLote = [];
          }

          // if (situacoesLotes.ENCERRADO) {
          //   this.listLotesEncerrados = situacoesLotes.ENCERRADO.map((ltE) => {
          //     ltE.Tempo = "00:00:00";
          //     ltE.MsgLote = "";
          //     return ltE;
          //   });
          // }

          // if (situacoesLotes.REPASSE) {
          //   this.listLotesEncerrados = situacoesLotes.REPASSE.map((ltE) => {
          //     ltE.Tempo = "00:00:00";
          //     ltE.MsgLote = "";
          //     return ltE;
          //   });
          // }

          //
          setTimeout(() => {
            this.setCronomentroTimeLotes();
          }, 1000);

          if (
            this.anoSelecionada.length > 0 &&
            this.marcaSelecionada.length > 0 &&
            this.modeloSelecionada.length > 0 &&
            this.corSelecionada.length > 0 &&
            this.localizacaoSelecionada.length > 0 &&
            this.buscaLivre !== ""
          ) {
            // console.log(
            //   this.anoSelecionada,
            //   this.marcaSelecionada,
            //   this.modeloSelecionada,
            //   this.corSelecionada,
            //   this.localizacaoSelecionada,

            // );
            if (this.listLote) {
              this.colocaMsgTempoExtraEmLotes(antigaLista, this.listLote);
            }
          }

          if (this.loteModal) {
            if (JSON.parse(JSON.stringify(this.loteModal)).LoteCod) {
              let itemLoteSelecioado = JSON.parse(
                JSON.stringify(this.loteModal)
              );

              this.atualizaDadosLoteSelecionadoDisputa(
                this.listLote,
                itemLoteSelecioado.LoteCod
              );

              let modalDisputaAberto = this.dialog2;

              let loteNovo = this.listLote.find(
                (l) => l.StatusPregao === "206" && l.LoteSituacao === "ABERTO"
              );

              if (loteNovo && modalDisputaAberto) {
                this.carregaModal(loteNovo);
              }
            }
          }

          let comecouDisputa = this.listLote.find(
            (l) => l.StatusPregao === "206" && l.LoteSituacao === "ABERTO"
          );

          //
          if (comecouDisputa) {
            this.playSound("audio5.mp3");

            this.$router
              .push({
                name: "Martelinho",
                params: {
                  name: this.$route.params.name,
                  hashValmiki: this.$route.params.hashValmiki,
                  codPessoaLogado: this.$route.params.codCliente,
                  hashPessoaLogado: this.$route.params.hashPessoa,
                  apelido: this.$route.params.apelido,
                  loteDetaque: this.$route.params.loteDetaque,
                },
              })
              .catch((err) => {});
          }

          // this.listLote = jsonLotes.ListLotes.map((e) => {
          // });
          // this.setCronomentroNew();

          // this.setCronomentro();
          // let frequencia = 1000;
          // for (let i = 0; i < this.listLote.length; i++) {

          //     this.setCronomentroNew(this.listLote[i], frequencia);

          // }
          this.loadingBuscaLivre = false;
        })
        .catch((e) => {
          console.error(
            "Excecao ao buscar dados da api para exibir os cards ",
            e.message
          );
          this.errorProcesso("Erro no servidor", "e");
          this.loadingBuscaLivre = false;
        })
        .finally(() => {
          this.loadingBuscaLivre = false;
        });
    },
    carregaModal(lote) {
      // this.valorLanceAutomatico = "";

      if (lote) {
        // if (lote.StatusPregao === "206" && lote.LoteSituacao === "ABERTO") {
        //   // modal disputa
        //   this.dialog2 = true;
        // } else {
        //   this.dialog = true;
        // }

        this.dialog = true;

        this.playSound("audio3.mp3");
        this.loteModal = lote;
        this.active_tab = "Inicio";
        this.dadosInfoLote = {};
        this.valorLanceAutomatico = this.loteModal?.LanceAutomatico;
      } else {
        this.fechaModal();
      }
    },
    fechaModal() {
      this.dialog = false;
      this.dialog2 = false;
      this.loteModal = {};
      this.active_tab = "Inicio";
      this.dadosInfoLote = {};
      this.valorLanceAutomatico = "";
      this.dialogFotos = false;
    },
    carregaTempoServidor() {
      this.leilao = this.$route.params.name;
      this.hash = this.$route.params.hashValmiki;
      this.usuarioLogadoDados.codPessoaLogado = this.$route.params.codCliente;
      this.usuarioLogadoDados.hashPessoaLogado = this.$route.params.hashPessoa;
      this.usuarioLogadoDados.apelidoPessoaLogado = this.$route.params.apelido;

      if (!this.leilao) {
        // alert("leilao não passado, caso o erro persista, entre contato com TI");
        this.$toastr.w(
          "leilao não passado, caso o erro persista, entre contato com TI"
        );

        console.error(
          "o paramentro leilao não existe para carregar tempo do servidor"
        );
        this.loading = false;
      }

      let timeServidorAjax = setInterval(() => {
        let baseUrlTimeServidor = `https://central.carbuy.com.br/frm3Realizacao/Json/TimeServidor.aspx?LeilaoCod=${this.leilao}&Hash=${this.hash}&PessoaCod=${this.usuarioLogadoDados.codPessoaLogado}&Apelido=${this.usuarioLogadoDados.apelidoPessoaLogado}&PessoaHash=${this.usuarioLogadoDados.hashPessoaLogado}`;
        this.$axios
          .get(`${baseUrlTimeServidor}`)
          .then((response) => {
            let retorno = response.data.Retorno;
            if (retorno.Retorno === "False") {
              // alert(retorno.Descricao);
              this.$toastr.w(retorno.Descricao);
              clearInterval(timeServidorAjax);
              this.$router.push("/");
              return;
            }

            //recarregar pagina do usuario
            if (response.data.TimeServidor.Reload === "True") {
              console.log("VEIO TRUE");
              this.$toastr.w("Página será recarregada");

              this.$router.go(0);
            }

            let timeConfig = response.data.TimeServidor;
            this.infoServidor = false;
            // mudancao na listagem
            if (timeConfig.incrementaTempo === "True") {
              //this.$router.go(0);
              this.carregaCards(
                "servidor",
                this.precoSelecionadoDe,
                this.precoSelecionadoAte,
                this.kmSelecionadoDe,
                this.kmSelecionadoAte,
                this.buscaLivre
              );
              this.infoServidor = true;

              // this.loadListagem();
            }

            //habilitar/desalibilitar usuairo para dar lance
            if (response.data.TimeServidor.Permissao === "True") {
              this.isHabilitado = true;
            } else {
              this.isHabilitado = false;
            }
            // Defina a data em que estamos fazendo a contagem regressiva baseado no tempo do servidor
            this.tempoServido = new Date(timeConfig.HoraServidor).getTime();
            //
            this.tempoAtualDoServidor = timeConfig.HoraServidor;
          })
          .catch((e) => {
            console.error(
              "Excecao ao buscar dados da api para pegar dados no horario do servidor",
              e.message
            );
            this.errorProcesso("Erro no servidor", "e");
            setTimeout(() => {
              this.$router.go(0);
              clearInterval(timeServidorAjax);
            }, 4000);
          });
      }, 1000);
    },
    setCronomentro() {
      this.listLote.forEach((lote) => {
        let loteTime = new Date(lote.Tempo).getTime();
        this.cronometroCard = setInterval(() => {
          let formatDataServidor = moment(this.tempoServido);

          let dateServidor = new Date(formatDataServidor._i).getTime();
          // Obtem data e hora de hoje
          var now = new Date().getTime();

          let statusAtual = loteTime - dateServidor;

          //Se foi passado tempo adicional
          if (lote.timeExtra) {
            let loteTimeExtra = new Date(lote.timeExtra).getTime();
            statusAtual = loteTimeExtra - now;
            lote.MsgLote = "TEMPO EXTRA!";
            this.msgIncrementandoTime = setTimeout(() => {
              lote.MsgLote = "";
            }, 5000);
            lote.timeExtra = "";
            // clearTimeout(this.msgIncrementandoTime);
          }

          var dias = Math.floor(statusAtual / (1000 * 60 * 60 * 24));
          var hora = Math.floor(
            (statusAtual % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
          );
          var min = Math.floor((statusAtual % (1000 * 60 * 60)) / (1000 * 60));
          var seconds = Math.floor((statusAtual % (1000 * 60)) / 1000);

          switch (lote.Tempo) {
            case "0:0:10":
              lote.MsgLote = "TEMPO ESGOTANDO !";
              break;
            case "0:0:9":
              lote.MsgLote = "TEMPO ESGOTANDO !";
              break;
            case "0:0:8":
              lote.MsgLote = "TEMPO ESGOTANDO !";
              break;
            case "0:0:7":
              lote.MsgLote = "TEMPO ESGOTANDO !";
              break;
            case "0:0:6":
              lote.MsgLote = "TEMPO ESGOTANDO !";
              break;
            case "0:0:5":
              lote.MsgLote = "TEMPO ESGOTANDO !";
              break;
            case "0:0:4":
              lote.MsgLote = "TEMPO ESGOTANDO !";
              break;
            case "0:0:3":
              lote.MsgLote = "TEMPO ESGOTANDO !";
              break;
            case "0:0:2":
              lote.MsgLote = "TEMPO ESGOTANDO !";
              break;
            case "0:0:1":
              lote.MsgLote = "TEMPO ESGOTANDO !";
              break;
          }

          if (dias > 0) {
            lote.Tempo = `${dias} d, ${hora}:${min}:${seconds}`;
          } else {
            lote.Tempo = `${hora}:${min}:${seconds}`;
          }

          if (statusAtual < 0) {
            clearInterval(this.cronometroCard);
            lote.MsgLote = "TEMPO ENCERRADO !";
            lote.Tempo = "00:00:00";
          }
        }, 1000);
      });
      // return lote;
    },
    pollData() {
      //Metodo pegar dados naquele nesse interva
      this.polling = setInterval(() => {
        this.$store.dispatch("RECUPERAR_DADOS_DO_BACKEND");
      }, 1000);
    },
    loadListagem() {
      this.leilao = this.$route.params.name;

      if (!this.leilao) {
        this.loading = false;
      }
      let baseUrlCard = `https://central.carbuy.com.br/frm3Realizacao/Json/Lotes.aspx?LeilaoCod=${this.leilao}&Hash=${this.hash}`;
      this.$axios
        .get(`${baseUrlCard}`)
        .then((response) => {
          const listagemLotesAtuais = response.data;
          this.listLote = listagemLotesAtuais.ListLotes;
        })
        .catch((e) => {
          console.error(
            "Excecao ao buscar dados atualizado da lista de card da api para exibir os cards ",
            e.message
          );
          // this.errorProcesso(
          //   "Ocorreu um erro ao buscar ao dados, caso persista entre com contato com Vip TI slz, acessar Chrome dev tools para mais informações"
          // );
          this.errorProcesso("Erro no servidor", "e");
        })
        .finally(() => (this.loading = false));
    },
    getLances(codigoLote, loteHash) {
      if (!codigoLote) {
        // alert("Desculpe ocorreu um erro ao buscar lances");
        this.$toastr.e("Erro no servidor");
        this.playSound("audio6.mp3");
      }
      let baseUrlLances = `https://central.carbuy.com.br/frm3Realizacao/Json/Lances.aspx?LoteCod=${codigoLote}&LoteHash=${loteHash}`;
      this.loading = true;
      this.$axios
        .get(`${baseUrlLances}`)
        .then((response) => {
          const listLote = response.data;
          this.listLance = listLote.Lances;
        })
        .catch((e) => {
          console.error(
            "Excecao ao buscar dados lista de lances do lote passado",
            e.message
          );
          // alert(
          //   "Ocorreu um erro ao buscar ao dados, caso persista entre com contato com Vip TI slz, acessar Chrome dev tools para mais informações"
          // );
          this.$toastr.e("Erro no servidor");
          this.playSound("audio6.mp3");
        })
        .finally(() => (this.loading = false));
    },
    pegarConfiguracaoForcado(leilao, hash) {
      let urlBaseConfig = `https://central.carbuy.com.br/frm3Realizacao/Json/Config.aspx?LeilaoCod=${leilao}&Hash=${hash}&PessoaCod=${this.$route.params.codCliente}&PessoaHash=${this.$route.params.hashPessoa}`;
      this.loading = true;
      this.$axios
        .get(`${urlBaseConfig}`)
        .then((response) => {
          const config = response.data;
          this.listLotesCombo = config.LotesCombo;
          this.configLeilao = config.ConfigLeilao;
        })
        .catch((e) => {
          console.error(
            "Excecao ao buscar dados da api para setar informações de configurações base do leilao ",
            e.message
          );
          this.$toastr.e("Erro no servidor");
          this.playSound("audio6.mp3");
        })
        .finally(() => (this.loading = false));
    },
    DarLance(dadosLote, lanceEnviado) {
      if (dadosLote.Tempo === "00:00:00") {
        this.$toastr.w("tempo lote encerrado");
        return;
      }
      // console.log(lanceEnviado);

      lanceEnviado = lanceEnviado.replace("R$ ", "");

      let urlBaseConfig = `https://central.carbuy.com.br/frm3Realizacao/Json/Grava.aspx?Tipo=Lance&LoteCod=${dadosLote.LoteCod}&LoteHash=${dadosLote.LoteHash}&Hash=${this.hash}&LeilaoCod=${this.leilao}&PessoaCod=${this.usuarioLogadoDados.codPessoaLogado}&Apelido=${this.usuarioLogadoDados.apelidoPessoaLogado}&PessoaHash=${this.usuarioLogadoDados.hashPessoaLogado}`;
      this.loadingBtn = true;
      let formDataLance = new FormData();

      let numeroLanceVencendo =
        dadosLote.VencendoNumero !== "-"
          ? Number(dadosLote.VencendoNumero) + 1
          : 1;

      formDataLance.append(
        "json",
        JSON.stringify({
          PessoaCod: this.usuarioLogadoDados.codPessoaLogado,
          LanceNumero: numeroLanceVencendo,
          ValorLance: lanceEnviado,
          URL: window.location.href,
          Apelido: this.usuarioLogadoDados.apelidoPessoaLogado,
        })
      );
      this.$axios
        .post(`${urlBaseConfig}`, formDataLance, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        })
        .then((response) => {
          const corpoRetorno = response.data.ListLotes[0];

          //
          corpoRetorno.Tempo = dadosLote.Tempo;

          this.loteModal = corpoRetorno;
          // this.loteModal = corpoRetorno
          // this.$toastr.s("Lance enviado");
          this.msgSnabar.top = true;
          this.playSound("audio4.mp3");
        })
        .catch((e) => {
          this.$toastr.e("Erro no servidor");
          this.loadingBtn = false;
          this.playSound("audio6.mp3");
        })
        .finally(() => (this.loadingBtn = false));
    },
    playSound(audioFile) {
      var sound = new Audio(require(`../assets/sons/${audioFile}`));

      sound.play();
      sound.muted = false;
    },
    setCronomentroNew() {
      this.listLote.forEach((lote, i) => {
        let tempoFormatodoPMomento = this.trataDataMoment(lote.Tempo);
        let formatDataServidor = moment(this.tempoServido);

        let dateServidor = new Date(formatDataServidor._i);

        var duration = moment.duration(tempoFormatodoPMomento);
        // var timestamp = new Date(0, 0, 0, 2, 10, 30);
        var timestamp = dateServidor;

        var interval = 1;

        timestamp = new Date(timestamp.getTime() + interval * 1000);

        duration = moment.duration(duration.asSeconds() - interval, "seconds");
        var min = duration.minutes();
        var sec = duration.seconds();
        var hours = duration.hours();
        var days = duration.days();

        sec -= 1;
        if (min < 0) {
          return clearTimeout(this.cronometroCard);
        }
        if (min < 10 && min.length != 2) {
          min = "0" + min;
        }
        if (sec < 0 && min != 0) {
          min -= 1;
          sec = 59;
        } else if (sec < 10 && length.sec != 2) {
          sec = "0" + sec;
        }

        lote.Tempo = days + "d " + hours + ":" + min + ":" + sec;

        if (min == 0 && sec == 0) {
          clearTimeout(this.cronometroCard);
        }
      });
      this.cronometroCard = setTimeout(() => {
        this.setCronomentroNew();
      }, 1000);
    },
    trataDataMoment(objTempo) {
      let dataAndHora = objTempo.split(" ");
      let dia = dataAndHora[0];
      let hoursMinSecons = dataAndHora[1];
      let trataNumeroMoment = dataAndHora[1].split(":");
      let tempo = {
        hours: Number(trataNumeroMoment[0]),
        minutes: Number(trataNumeroMoment[1]),
        seconds: Number(trataNumeroMoment[2]),
      };
      return tempo;
    },
    setCronomentroTimeLotes() {
      let configTimeEncerrando = this.configLeilao.TempoEncerrando || "30";

      if (!this.tempoAtualDoServidor) {
        // tempo do servidor sempre deve existe p/ todos usuarios
        this.$toastr.w("Sincronizando cronômetro");

        setTimeout(() => {
          this.$router.go(0);
        }, 800);
      }
      this.listLote.forEach((lote, i) => {
        // console.log(lote.Tempo);
        if (lote.Tempo !== "00:00:00") {
          let hd = moment(lote.TempoPadrao);
          let formatado = hd.format("DD-MM-YYYY HH:mm:ss");
          let hdTempoServidor = moment(this.tempoAtualDoServidor);
          let formatadoTempoServidor = hdTempoServidor.format(
            "DD-MM-YYYY HH:mm:ss"
          );
          var eventTime = moment(formatado, "DD-MM-YYYY HH:mm:ss").unix();

          var currentTime = moment().unix();
          var currentTime2 = moment(
            formatadoTempoServidor,
            "DD-MM-YYYY HH:mm:ss"
          ).unix();

          //
          var diffTime = eventTime - currentTime2;
          // var diffTimePc = eventTime - currentTime;
          var duration = moment.duration(diffTime * 1000, "milliseconds");
          // // // console.log(1);
          // console.log("Servido -> ", this.tempoAtualDoServidor);
          let isEncerrado = hd.format("D") != hdTempoServidor.format("D");
          if (diffTime > 0) {
            duration = moment.duration(
              duration.asMilliseconds() - 1000,
              "milliseconds"
            );
            var d = moment.duration(duration).days(),
              h = moment.duration(duration).hours(),
              m = moment.duration(duration).minutes(),
              s = moment.duration(duration).seconds();

            d = d.length === 1 ? "0" + d : d;
            h = h.length === 1 ? "0" + h : h;
            m = m.length === 1 ? "0" + m : m;
            s = s.length === 1 ? "0" + s : s;

            lote.Tempo = `${d}d ${h}:${m}:${s}`;

            let progressMinuteBar = Math.floor(diffTime / 60); // numero leva em conta o min
            lote.Progresso =
              (progressMinuteBar === 0) &
              (progressMinuteBar <= parseInt(configTimeEncerrando))
                ? parseInt(s)
                : progressMinuteBar;
            // lote.Progresso = Math.floor(diffTime / 100);
            let tempoEncerramentoConfig = `0:0:${configTimeEncerrando}`;
            let tempoEncerramento = `${d}d 0:0:${s}`;

            if (lote.timeExtra) {
              lote.MsgLote = "TEMPO EXTRA!";
              // setTimeout(() => {
              //   lote.timeExtra = false
              // }, 3000);
            }

            if (lote.Tempo === tempoEncerramento && d === 0) {
              if (s < configTimeEncerrando && d === 0) {
                lote.MsgLote = "TEMPO ESGOTANDO !";
                // if (s <= 3) {
                //   this.playSound("audio5.mp3");
                // }
              } else {
                lote.MsgLote = "";
              }
            } else {
              //
              if (lote.MsgLote !== "TEMPO EXTRA!") {
                lote.MsgLote = "";
              }
              //lote.MsgLote = "";
            }

            if (lote.Tempo === "0d 0:0:0" && d === 0) {
              lote.Tempo = "00:00:00";
              lote.Progresso = 0;
              //
              clearTimeout(this.cronometroCard);

              this.homologandoLoteEncerrado(lote);
              return;
            }
          } else {
            // Quer dizer tempo do lote é menor que tempo atual
            // lote.Tempo = "00:00:00";
            this.jaEncerradoLote(lote);
          }
        } else {
          this.jaEncerradoLote(lote);
        }
      });
      this.cronometroCard = setTimeout(() => {
        this.setCronomentroTimeLotes();
      }, 1000);
    },
    jaEncerradoLote(lote) {
      lote.Tempo = "00:00:00";
      lote.Progresso = 0;
      lote.MsgLote = "";
      clearTimeout(this.cronometroCard);
      // força item que está status em abert mas com o tempo encerrado a ser homologado
      this.homologandoLoteEncerrado(lote);
      //this.fechaModal();

      return;
    },
    homologandoLoteEncerrado(loteEncerrado) {
      // const loteAtual = this.listLote.find(lote => lote === loteEncerrado);
      if (
        loteEncerrado.LoteSituacao === "ABERTO" &&
        loteEncerrado.StatusPregao === "131"
      ) {
        this.fechaModal();

        let urlBaseConfig = `https://central.carbuy.com.br/frm3Realizacao/Json/Grava.aspx?Tipo=Encerrado&LoteCod=${loteEncerrado.LoteCod}&LoteHash=${loteEncerrado.LoteHash}&Hash=${this.hash}&LeilaoCod=${this.leilao}&PessoaCod=${this.usuarioLogadoDados.codPessoaLogado}&Apelido=${this.usuarioLogadoDados.apelidoPessoaLogado}&PessoaHash=${this.usuarioLogadoDados.hashPessoaLogado}`;
        let formHomologandoLote = new FormData();

        formHomologandoLote.append(
          "Json",
          JSON.stringify({
            PessoaCod: loteEncerrado.VencendoPessoaCod,
            LanceNumero: loteEncerrado.VencendoNumero,
            ValorLance: loteEncerrado.VencendoValor,
            URL: window.location.href,
            Apelido: loteEncerrado.VencendoApelido,
          })
        );
        this.$axios
          .post(`${urlBaseConfig}`, formHomologandoLote, {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          })
          .then((response) => {
            const corpoRetorno = response.data.ListLotes[0];
            corpoRetorno.Tempo = loteEncerrado.Tempo;

            this.loteModal = corpoRetorno;
            this.msgSnabar.top = true;
            //this.playSound("audio1.mp3");
          })
          .catch((e) => {
            this.$toastr.e("Erro no servidor");
            this.playSound("audio6.mp3");
          });
        // .finally(() => ());
      }
    },
    onScroll(e) {
      if (typeof window === "undefined") return;
      const top = window.pageYOffset || e.target.scrollTop || 0;
      this.fab = top > 20;
    },
    toTop() {
      this.$vuetify.goTo(0);
    },
    atualizaDadosLoteSelecionadoDisputa(arrayLotes, loteSelecionaoda) {
      let loteInformado = loteSelecionaoda ?? null;
      // console.log(
      //   "Nova listagem => ",
      //   arrayLotes,
      //   "Lote selecionado =>",
      //   loteInformado
      // );

      if (loteInformado) {
        let loteSeleciodaoEmDisputa = arrayLotes.find(
          (a) => a.LoteCod == loteSelecionaoda
        ); // lotes em aberto

        //
        this.loteModal = loteSeleciodaoEmDisputa;
        this.valorLanceAutomatico = this.loteModal?.LanceAutomatico;
        this.msgSnabar.top = true;
      }
    },
    colocaMsgTempoExtraEmLotes(antigalista, novaLista) {
      //
      if (antigalista.length) {
        let lotesDiferentes = novaLista.filter(
          (item1) =>
            !antigalista.some(
              (item2) =>
                item2.TempoPadrao === item1.TempoPadrao &&
                item2.EstoqueCod === item1.EstoqueCod
            )
        );

        if (lotesDiferentes.length) {
          this.listLote.forEach((lote_item, index) => {
            let novo_lote = lotesDiferentes.find(
              (a) => a.EstoqueCod === lote_item.EstoqueCod
            );

            let existeLoteTempoDiferente =
              novo_lote && novo_lote.EstoqueCod === lote_item.EstoqueCod;
            if (existeLoteTempoDiferente) {
              if (lote_item) {
                lote_item.MsgLote = "TEMPO EXTRA!";
                this.playSound("audio5.mp3");
                //this.msgSnabar.text = `MAIS TEMPO N*: ${lote_item.LoteNumero}`;
                // this.msgSnabar.top = true;
                //this.msgSnabar.left = true;
                setTimeout(function () {
                  lote_item.MsgLote = "";
                }, 4000);
              }
            }
          });
        }
      }
    },
    carregaInfosLote(codigoLote, loteHash) {
      if (!codigoLote) {
        console.error(
          "Codigo lote não foi passado para buscar dados informativos do lote"
        );

        // alert("Desculpe ocorreu um erro ao buscar lances");
        this.$toastr.e("Erro no servidor");
        this.playSound("audio6.mp3");
      }
      let baseUrlInfos = `https://central.carbuy.com.br/frm3Realizacao/Json/Lote.aspx?LoteCod=${codigoLote}&Hash=${loteHash}`;
      this.loading = true;
      this.$axios
        .get(`${baseUrlInfos}`)
        .then((response) => {
          let loteInfor = response.data;
          this.dadosInfoLote =
            loteInfor && Array.isArray(loteInfor) && loteInfor.length
              ? loteInfor[0]
              : {};
        })
        .catch((e) => {
          console.error(
            "Excecao ao buscar dados informativos do lote",
            e.message
          );
          // alert(
          //   "Ocorreu um erro ao buscar ao dados, caso persista entre com contato com Vip TI slz, acessar Chrome dev tools para mais informações"
          // );
          this.$toastr.e("Erro no servidor");
          this.playSound("audio6.mp3");
        })
        .finally(() => (this.loading = false));
    },
    corPorEmpresaCardHeader(empresa) {
      let cor = "primary";
      switch (empresa?.toLowerCase()) {
        case "hastavip":
          cor = "warning";
          break;
        case "carbuy":
          cor = "#E02448";
          break;
      }

      return cor;
    },
    carregaComoboxValmiki(paramentros = "") {
      let query = paramentros ? "&" + paramentros : "";
      let baseUrlComobox = `https://central.carbuy.com.br/frm3Realizacao/Json/ConfigFiltro.aspx?LeilaoCod=${this.leilao}&Hash=${this.hash}&PessoaHash=${this.usuarioLogadoDados.hashPessoaLogado}&PessoaCod=${this.usuarioLogadoDados.codPessoaLogado}${query}`;
      this.estoquesCodSelecionado = "";
      this.$axios
        .get(`${baseUrlComobox}`)
        .then((response) => {
          let corpo = response.data;
          if (corpo.Retorno.Retorno === "True") {
            if (corpo.EstoqueCod.length) {
              this.estoquesCodSelecionado = corpo.EstoqueCod
                ? "&EstoqueCod=" +
                  corpo.EstoqueCod.map((a) => a.EstoqueCod).join(",")
                : "";
            } else {
              // this.$toastr.w("Não localizado itens");
              this.estoquesCodSelecionado = 0;
            }

            this.anos = corpo.Ano ? corpo.Ano.map((a) => a.Ano) : [];

            this.combustiveis = []; //corpo.Combustivel ? corpo.Combustivel.map((a) => a.Combustivel) : [];

            this.marcas = corpo.Marca
              ? corpo.Marca.map((a) => a.MarcaNome)
              : [];
            this.modelos = corpo.Modelo
              ? corpo.Modelo.map((a) => a.Modelo)
              : [];
            this.localizacoes = corpo.Localizacao
              ? corpo.Localizacao.map((a) => a.Localizacao)
              : [];
            this.cores = corpo.Cor ? corpo.Cor.map((a) => a.CorNome) : [];
          } else {
            this.$toastr.w("Impossivel conectar com a comobox");
          }
        })
        .catch((e) => {
          console.error(
            "Excecao ao buscar dados para listar comobox",
            e.message
          );
          // alert(
          //   "Ocorreu um erro ao buscar ao dados, caso persista entre com contato com Vip TI slz, acessar Chrome dev tools para mais informações"
          // );
          this.$toastr.e("Erro no servidor");
          this.playSound("audio6.mp3");
        })
        .finally(() => (this.loading = false));
    },
    exibeLotePreSelecionado(loteInformadoViaUrl) {
      if (loteInformadoViaUrl) {
        let lote = this.listLote.find(
          (a) => a.LoteCod == String(loteInformadoViaUrl)
        );
        // console.log(lote);
        if (lote) {
          this.carregaModal(lote);
        }
      }
    },
  },
};
</script>

<style lang="css">
.brade-comobox label {
  font-size: 14px;
}

.brade-comobox input {
  font-size: 14px;
}

.brade-comobox .v-text-field__prefix {
  font-size: 14px;
}

.brade-comobox .v-text-field__suffix {
  font-size: 14px;
}

.listagem-card-brade {
  display: grid;
  grid-template-columns: repeat(3, 306px);
  grid-gap: 33px;
  padding: 0;
}

@media (min-width: 1360px) {
  .container {
    max-width: 1314px;
  }
}

@media (min-width: 1904px) {
  .container {
    max-width: 1785px;
  }
}

.brade-filters-mobile {
  display: none;
}

@media (max-width: 752px) {
  .brade-filters-mobile {
    display: flex;
  }

  .brade-filters-desktop {
    display: none;
  }
}

/** ITEM FILTER **/
@media all {
  .itf-sidecr {
    padding-right: 33px;
    width: 306px;
  }

  .itf-sidecr .itf-head {
    align-items: flex-end;
    border-bottom: 1px solid #9c9c9c;
    display: flex;
    min-height: 36px;
  }

  .itf-sidecr .itf-head .itf-mode {
    color: #9c9c9c;
    font-family: "Roboto", sans-serif;
    font-size: 14px;
    font-weight: 400;
    margin: 0;
  }

  .itf-sidecr label {
    color: #9c9c9c;
    font-family: "Roboto", sans-serif;
    font-size: 14px;
    font-weight: 400;
    margin: 0;
  }

  .itf-sidecr .itf-lineone {
    margin-top: 10px;
  }

  .itf-sidecr .itf-linetwo {
    display: flex;
  }

  .itf-sidecr .itf-linetwo .form-group {
    display: flex;
    flex-direction: column;
    width: 100%;
  }

  .itf-sidecr .itf-linetwo .form-group + .form-group {
    margin-left: 10px;
  }

  .itf-sidecr .itf-linethree {
    display: flex;
  }

  .itf-sidecr .itf-linethree .form-group {
    display: flex;
    flex-direction: column;
    width: 100%;
  }

  .itf-sidecr .itf-linethree .form-group + .form-group {
    margin-left: 10px;
  }

  .itf-sidecr .itf-linefour .form-group {
    font-family: "Roboto", sans-serif;
    margin-bottom: 5px;
  }

  .mob-itf-sidecr {
    display: none;
  }
}

.hr-text {
  /* line-height: 1em;
  position: relative;
  outline: 0;
  border: 0;
  color: black;
  text-align: center;
  height: 1.5em;
  opacity: 0.5; */
  background: linear-gradient(90deg, #120000 0%, #e90000 100%);
  height: 4px;
}

/* classe deve ser aplicada somente quando tiver minha classe customizada */
/* .v-dialog__content {
  align-items: baseline;
  justify-content: right;
} */

/* fim classe deve ser aplicada somente quando tiver minha classe customizada */

.v-btn--example {
  bottom: 0;
  position: absolute;
  margin: 0 0 16px 16px;
}
.box {
  -o-text-overflow: ellipsis;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  width: 350px;
}

/*banner card inicio*/
.ribbon-wrapper {
  position: static;
  z-index: 998;
}
.ribbon-front {
  background-color: #cc3333;
  height: 50px;
  width: 306px;
  margin: auto;
  position: relative;
  left: -0px;
  z-index: 2;
  font: 20px/50px bold Verdana, Geneva, sans-serif;
  color: #f8f8f8;
  text-align: center;
  text-shadow: 0px 1px 2px #cc6666;
}

.ribbon-front,
.ribbon-back-left,
.ribbon-back-right {
  -moz-box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.55);
  -khtml-box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.55);
  -webkit-box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.55);
  box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.55);
  -o-box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.55);
}

.ribbon-edge-topleft,
.ribbon-edge-topright,
.ribbon-edge-bottomleft,
.ribbon-edge-bottomright {
  position: absolute;
  z-index: 1;
  border-style: solid;
  height: 0px;
  width: 0px;
}

/* .ribbon-edge-topleft,
.ribbon-edge-topright {
  } */

.ribbon-edge-bottomleft,
.ribbon-edge-bottomright {
  top: 50px;
}

.ribbon-edge-topleft,
.ribbon-edge-bottomleft {
  left: -10px;
  border-color: transparent #9b1724 transparent transparent;
}

.ribbon-edge-topleft {
  top: -5px;
  border-width: 5px 10px 0 0;
}
.ribbon-edge-bottomleft {
  border-width: 0 10px 0px 0;
}

.ribbon-edge-topright,
.ribbon-edge-bottomright {
  left: 220px;
  border-color: transparent transparent transparent #9b1724;
}

.ribbon-edge-topright {
  top: 0px;
  border-width: 0px 0 0 10px;
}
.ribbon-edge-bottomright {
  border-width: 0 0 5px 10px;
}

@-webkit-keyframes flow {
  0% {
    left: -20px;
    opacity: 0;
  }
  50% {
    left: 100px;
    opacity: 0.3;
  }
  100% {
    left: 180px;
    opacity: 0;
  }
}
@keyframes flow {
  0% {
    left: -20px;
    opacity: 0;
  }
  50% {
    left: 100px;
    opacity: 0.3;
  }
  100% {
    left: 180px;
    opacity: 0;
  }
}

.glow {
  background: rgb(255, 255, 255);
  width: 40px;
  height: 100%;
  z-index: 200;
  position: absolute;
  -webkit-animation: flow 1.5s linear infinite;
  -moz-animation: flow 1.5s linear infinite;
  -webkit-transform: skew(20deg);
  -moz-transform: skew(20deg);
  -o-transform: skew(20deg);
  background: -moz-linear-gradient(
    left,
    rgba(255, 255, 255, 0) 0%,
    rgba(255, 255, 255, 0) 1%,
    rgba(255, 255, 255, 1) 100%
  ); /* FF3.6+ */
  background: -webkit-gradient(
    linear,
    left top,
    right top,
    color-stop(0%, rgba(255, 255, 255, 0)),
    color-stop(1%, rgba(255, 255, 255, 0)),
    color-stop(100%, rgba(255, 255, 255, 1))
  ); /* Chrome,Safari4+ */
  background: -webkit-linear-gradient(
    left,
    rgba(255, 255, 255, 0) 0%,
    rgba(255, 255, 255, 0) 1%,
    rgba(255, 255, 255, 1) 100%
  ); /* Chrome10+,Safari5.1+ */
  background: -o-linear-gradient(
    left,
    rgba(255, 255, 255, 0) 0%,
    rgba(255, 255, 255, 0) 1%,
    rgba(255, 255, 255, 1) 100%
  ); /* Opera 11.10+ */
  background: -ms-linear-gradient(
    left,
    rgba(255, 255, 255, 0) 0%,
    rgba(255, 255, 255, 0) 1%,
    rgba(255, 255, 255, 1) 100%
  ); /* IE10+ */
  background: linear-gradient(
    to right,
    rgba(255, 255, 255, 0) 0%,
    rgba(255, 255, 255, 0) 1%,
    rgba(255, 255, 255, 1) 100%
  ); /* W3C */
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#00ffffff', endColorstr='#ffffff',GradientType=1 ); /* IE6-9 */
  border-left: 1px solid #fff;
}
/*banner card fim*/

/* edimar estilo modal */

.v-dialog {
  max-height: 100% !important;
}

.v-dialog .v-card header {
  background-color: #e5173f !important;
}

.v-dialog .v-card header .v-toolbar__content {
  padding: 20px;
}

.v-dialog .v-card header .v-toolbar__content button {
  background-color: transparent !important;
  box-shadow: none;
  padding: 0;
  min-width: 0;
  margin-left: 10px;
}

.v-dialog .v-card header .v-toolbar__content button i {
  font-size: 24px !important;
}

.v-dialog .v-card .v-tabs .v-tabs-bar {
  border-bottom: 2px solid #e5173f;
}

.v-tabs-slider {
  display: none !important;
}

.v-dialog .v-card .v-tabs .v-tabs-bar .v-tab {
  color: #e5173f !important;
  font-weight: bold;
  line-height: 1;
  width: 100%;
}

.v-dialog .v-card .v-tabs .v-tabs-bar .v-tab i {
  display: none;
}

.v-dialog .v-card .v-tabs .v-tabs-bar .v-tab.v-tab--active {
  background-color: #eeeeee !important;
}

.v-dialog .v-card .v-tabs .v-tabs-bar .v-tab.v-tab--active::after {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  height: 3px;
  background-color: #e5173f;
  content: "";
}

.v-dialog
  .v-card
  .v-tabs
  .v-window
  .v-window__container
  .v-window-item
  .v-card
  p {
  margin: 0 !important;
  width: 100%;
}

.v-dialog
  .v-card
  .v-tabs
  .v-window
  .v-window__container
  .v-window-item
  .v-card
  p
  img {
  height: 320px;
  margin: 0;
  object-fit: cover;
  width: 100%;
}

.v-dialog
  .v-card
  .v-tabs
  .v-window
  .v-window__container
  .v-window-item
  .v-card
  .v-alert {
  border: 0 !important;
  box-shadow: none !important;
  padding: 0;
}

.v-dialog
  .v-card
  .v-tabs
  .v-window
  .v-window__container
  .v-window-item
  .v-card
  .v-alert
  .v-alert__wrapper
  .v-icon {
  display: none;
}

.v-dialog
  .v-card
  .v-tabs
  .v-window
  .v-window__container
  .v-window-item
  .v-card
  .v-alert
  .v-alert__wrapper
  .v-alert__border {
  display: none;
}

.v-dialog
  .v-card
  .v-tabs
  .v-window
  .v-window__container
  .v-window-item
  .v-card
  .v-alert
  .v-alert__wrapper
  .v-alert__content
  h3 {
  color: #e5173f;
  font-size: 32px;
  font-weight: bold;
  padding: 5px 0;
  margin: 0 !important;
}

.v-dialog
  .v-card
  .v-tabs
  .v-window
  .v-window__container
  .v-window-item
  .v-card
  p {
  color: #7a7a7a;
  font-size: 14px;
  font-weight: normal;
  margin: 0 !important;
}

.v-dialog
  .v-card
  .v-tabs
  .v-window
  .v-window__container
  .v-window-item
  .v-card
  p
  span {
  font-weight: bold;
}

.v-dialog
  .v-card
  .v-tabs
  .v-window
  .v-window__container
  .v-window-item
  .v-card
  hr {
  margin: 10px 0;
  color: #7a7a7a;
}

.v-dialog
  .v-card
  .v-tabs
  .v-window
  .v-window__container
  .v-window-item
  .v-card
  .v-alert {
  margin: 0;
}

.v-dialog
  .v-card
  .v-tabs
  .v-window
  .v-window__container
  .v-window-item
  .v-card
  .brade-right {
  background: #ca2351;
  background: linear-gradient(45deg, #e5173f 0%, #ca2351 100%);
  border-radius: 8px;
  padding: 20px 20px 12px;
}

.v-dialog
  .v-card
  .v-tabs
  .v-window
  .v-window__container
  .v-window-item
  .v-card
  .brade-right
  p {
  color: #fff;
  font-weight: normal !important;
  font-size: 18px;
  display: flex;
  flex-direction: column;
  margin-bottom: 8px !important;
}

.v-dialog
  .v-card
  .v-tabs
  .v-window
  .v-window__container
  .v-window-item
  .v-card
  .brade-right
  .row.justify-center {
  align-items: center;
  flex-direction: column;
}

.v-dialog
  .v-card
  .v-tabs
  .v-window
  .v-window__container
  .v-window-item
  .v-card
  .brade-right
  button {
  background-color: #fff !important;
  box-shadow: none;
  margin-bottom: 8px;
  font-size: 16px !important;
  width: 100% !important;
}

.v-dialog
  .v-card
  .v-tabs
  .v-window
  .v-window__container
  .v-window-item
  .v-card
  .brade-right
  .subheading {
  width: 80% !important;
}

.v-dialog
  .v-card
  .v-tabs
  .v-window
  .v-window__container
  .v-window-item
  .v-card
  .brade-right
  .row.justify-center
  button
  span {
  color: #e5173f !important;
}

.v-dialog .v-card .v-card__actions {
  display: flex;
  align-items: center;
  justify-content: center;
}

.v-dialog .v-card .v-card__actions button {
  background-color: #e5173f !important;
  box-shadow: none;
  color: #fff !important;
  padding: 20px 30px !important;
}

.v-dialog
  .v-card
  .v-tabs
  .v-window
  .v-window__container
  .v-window-item
  .v-card
  h3 {
  color: #e5173f;
  font-size: 24px;
  font-weight: bold;
  text-transform: uppercase;
}

.brade-title {
  color: #1d1d1b !important;
  text-transform: uppercase;
  font-size: 16px !important;
  margin-bottom: 5px;
  padding-top: 20px;
  font-weight: bold !important;
}

.brade-descricao {
  margin: 0 !important;
  font-size: 14px;
  color: #7a7a7a;
}

.v-window-item .v-card {
  box-shadow: none !important;
}

.brade-col-right {
  border-left: 1px solid #a7a7a7;
  padding-left: 20px;
}

.brade-button-laudo {
  background-color: #e5173f !important;
  color: #fff !important;
  padding: 20px 30px !important;
  font-weight: normal;
  text-transform: none;
}

/* fim edimar estilo modal */

/* edimar estilo lotes */

.v-card.brade-card .v-list-item.theme--light {
  padding: 20px 20px 0;
}

.v-card.brade-card .v-list-item.theme--light .col.col-12 {
  padding: 0;
}

.v-card.brade-card
  .v-list-item.theme--light
  .col.col-12
  h3.text-center.brade-titulo-card {
  color: #3e3e3e !important;
  font-size: 20px !important;
  font-weight: 700 !important;
  line-height: 1;
  padding-bottom: 10px;
  height: 70px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.v-card.brade-card .v-list-item.theme--light .col.col-12 p.text-center {
  color: #3e3e3e !important;
  font-size: 14px !important;
  font-weight: 400 !important;
  line-height: 1;
  margin: 0;
}

.v-card.brade-card .v-list-item.theme--light .col.col-12 p.text-center b {
  font-weight: 900 !important;
}

.v-card.brade-card .v-list-item.theme--light .col.col-12 p.text-center i {
  color: #e5173f !important;
  margin-right: 5px;
}

.brade-divider {
  border-color: #e5173f !important;
  border-width: 3px !important;
  margin: 20px 0;
  max-width: 133px !important;
  /* font-size: 10px; */
}

.v-card.brade-card
  .v-list-item.theme--light
  .col.col-12
  h3.subtitle-1.text-center {
  color: #3e3e3e !important;
  font-size: 14px !important;
  font-weight: 700 !important;
  padding-bottom: 0 !important;
  text-transform: uppercase;
  line-height: 1.2 !important;
}

.v-card.brade-card .v-list-item.theme--light .col.col-12 h1.text-center {
  color: #e5173f !important;
  font-size: 30px !important;
  line-height: 1.2 !important;
}

.v-card.brade-card .col.col-12 {
  padding: 20px;
  padding-top: 10px;
}

.v-card.brade-card .col.col-12 .col.col-6 {
  color: #3e3e3e !important;
  font-size: 14px !important;
  line-height: 1;
  margin: 0;
  padding: 0 !important;
}

.v-card.brade-card .col.col-12 .col.col-6 i {
  color: #e5173f !important;
  margin-left: 0 !important;
  margin-right: 5px;
}

.v-card.brade-card .v-card__actions {
  padding: 0 20px 20px;
}

.v-card.brade-card .v-card__actions p.caption.text-left {
  align-items: center;
  border-radius: 20px;
  color: #e02448 !important;
  display: flex;
  font-size: 10px !important;
  font-weight: 900 !important;
  justify-content: flex-start;
  line-height: 1;
  margin: 0;
  min-width: 100px;
  padding: 8px 16px;
  text-transform: uppercase;
}

.v-card.brade-card .v-card__actions p.caption.text-right {
  align-items: center;
  border-radius: 20px;
  color: #e02448 !important;
  display: flex;
  font-size: 10px !important;
  font-weight: 900 !important;
  justify-content: flex-end;
  line-height: 1;
  margin: 0;
  min-width: 100px;
  padding: 8px 16px;
  text-transform: uppercase;
}

.v-card.brade-card .v-card__actions p.caption.text-right span {
  padding-left: 2px;
}

/* fim edimar estilo lotes */
</style>